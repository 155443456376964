
import { useStore } from "@/store";
import { computed, defineComponent, onMounted } from "vue";
import UserName from "@/components/UserName.vue";
import ChatHistory from "@/components/ChatHistory.vue";
import { ActionTypes } from "@/store/actions";
import ActiveSurvey from "@/components/ActiveSurvey.vue";
import {
  ChatMessage,
  ChatRoomMode,
  ChatSurvey as ChatSurveyType
} from "@/store/types";
import ActiveTopic from "@/components/ActiveTopic.vue";
import { useI18n } from "vue-i18n";
import { MutationTypes } from "@/store/mutations";
import ConfirmAndCheckDialog from "@/components/extended/ConfirmAndCheckDialog.vue";
import ConfirmDialog from "primevue/confirmdialog";

export default defineComponent({
  name: "ChatPage",
  components: {
    ConfirmDialog,
    ConfirmAndCheckDialog,
    UserName,
    ChatHistory,
    ActiveSurvey,
    ActiveTopic
  },
  props: {
    isApproval: {
      type: Boolean,
      required: true
    }
  },
  emits: ["page-changed"],
  setup: () => {
    const store = useStore();
    const { t } = useI18n();

    onMounted(async () => {
      await store.dispatch(ActionTypes.FETCH_MESSAGES);
    });

    return {
      t: t,
      ChatRoomMode: ChatRoomMode,
      user: computed(() => store.getters.getUser),
      activeRoom: computed(() => store.state.activeRoom),
      activeSurvey: computed(() => store.state.activeSurvey),
      activeTopic: computed(() => store.state.activeTopic),
      messages: computed(() => store.getters.getVisibleMessages),
      approvalMessages: computed(() => store.getters.getAllMessages),
      activeUserCount: computed(() => store.getters.getActiveUsersCount),
      profileSaving: computed(() => store.state.profileSaving),
      profileError: computed(() => store.state.profileError),

      submitUserName: async (userName: string) => {
        store.dispatch(ActionTypes.SET_USER_NAME, userName);
      },
      answerSurvey: (survey: ChatSurveyType, answer: string) => {
        store.dispatch(ActionTypes.ANSWER_SURVEY, {
          surveyUuid: "" + survey.uuid,
          answer: answer
        });
      },
      cancelSurvey: () => {
        store.commit(MutationTypes.SET_ACTIVE_SURVEY, null);
      },
      markMessageAsTopic: (message: ChatMessage) => {
        store.dispatch(ActionTypes.CREATE_TOPIC, message).then(() => {
          store.commit(MutationTypes.ADD_FLASH_MESSAGE, {
            type: "success",
            message: "message.topicCreated",
            title: "message.topicsTabTitle",
            duration: 4000
          });
        });
      },
      deleteMessage: (message: ChatMessage) => {
        store.dispatch(ActionTypes.DELETE_MESSAGE, message);
      },
      blockMessage: (message: ChatMessage) => {
        store.dispatch(ActionTypes.BLOCK_MESSAGE, message);
      },
      approveMessage: (message: ChatMessage) => {
        store.dispatch(ActionTypes.APPROVE_MESSAGE, message);
      },
      revertApprovalMessage: (message: ChatMessage) => {
        store.dispatch(ActionTypes.REVERT_APPROVAL_MESSAGE, message);
      },
      banUser: (message: ChatMessage) => {
        const uuid = message.user?.uuid;
        if (!uuid) {
          return;
        }

        store.dispatch(ActionTypes.BLOCK_USER, uuid);
      }
    };
  }
});
