
import { ChatSurvey, SurveyType } from "@/store/types";
import { defineComponent, PropType, ref } from "vue";
import { useI18n } from "vue-i18n";
import Button from "primevue/button";
import RadioButton from "primevue/radiobutton";
import InputText from "primevue/inputtext";

const INPUT_WARNING_THRESHOLD_NONE = 50;
const SURVEY_TEXT_MAX_LENGTH = 150;

export default defineComponent({
  name: "SurveyAnswerForm",
  components: {
    Button,
    RadioButton,
    InputText
  },
  props: {
    survey: {
      type: Object as PropType<ChatSurvey>,
      required: true
    },
    onAnswered: {
      type: Function as PropType<(survey: ChatSurvey, answer: string) => void>,
      required: true
    },
    onCancel: {
      type: Function as PropType<() => void>,
      required: false,
      default: undefined
    }
  },
  setup: props => {
    const { t } = useI18n();
    const answerModel = ref(-1);
    const answerText = ref<string>("");

    const canSubmit = () => {
      if (
        props.survey.type === SurveyType.MULTIPLE_CHOICE &&
        answerModel.value === -1
      ) {
        return false;
      }
      if (
        (props.survey.type === SurveyType.FREE_TEXT &&
          answerText.value.trim() === "") ||
        (props.survey.type === SurveyType.FREE_TEXT &&
          answerText.value.length >= SURVEY_TEXT_MAX_LENGTH)
      ) {
        return false;
      }
      return true;
    };

    const getClasses = () => {
      return {
        none: answerText.value.length <= INPUT_WARNING_THRESHOLD_NONE,
        warning: answerText.value.length > SURVEY_TEXT_MAX_LENGTH - 10,
        error: answerText.value.length >= SURVEY_TEXT_MAX_LENGTH
      };
    };

    const submitAnswer = () => {
      if (!canSubmit()) {
        return;
      }
      const answerKey = answerModel.value;
      if (
        props.survey.type === SurveyType.MULTIPLE_CHOICE &&
        answerKey !== -1
      ) {
        props.onAnswered(props.survey, props.survey.options[answerKey]);
      }
      if (
        props.survey.type === SurveyType.FREE_TEXT &&
        answerText.value !== ""
      ) {
        props.onAnswered(props.survey, answerText.value);
      }
    };

    const cancel = () => {
      answerText.value = "";
      answerModel.value = -1;
      props.onCancel();
    };
    return {
      SurveyType,
      t,
      answerModel,
      answerText,
      textMaxLength: SURVEY_TEXT_MAX_LENGTH,
      getClasses,
      canSubmit,
      submitAnswer,
      cancel
    };
  }
});
