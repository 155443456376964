
import { computed, defineComponent, onMounted, ref } from "vue";
import Dialog from "primevue/dialog";
import SurveySubmitForm from "@/components/SurveySubmitForm.vue";
import { ChatSurvey } from "@/store/types";
import { useStore } from "vuex";
import { ActionTypes } from "@/store/actions";
import ChatSurveyList from "@/components/ChatSurveyList.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "SurveysPage",
  components: {
    Dialog,
    SurveySubmitForm,
    ChatSurveyList
  },
  props: {
    minLength: {
      type: Number,
      default: () => 2
    },
    maxLength: {
      type: Number,
      default: () => 4
    }
  },
  setup: () => {
    const store = useStore();
    const { t } = useI18n();

    const clickedSurvey = ref<ChatSurvey | null>(null);
    const displayDialog = ref<boolean>(false);

    const sendSurvey = (survey: ChatSurvey) => {
      store.dispatch(ActionTypes.SEND_SURVEY, survey).then(() => {
        store.dispatch(ActionTypes.FETCH_SURVEYS);
      });
    };
    const updateSurvey = (survey: ChatSurvey) => {
      store.dispatch(ActionTypes.UPDATE_SURVEY, survey).then(() => {
        store.dispatch(ActionTypes.FETCH_SURVEYS);
      });
    };
    const openCreateSurveyDialog = (survey?: ChatSurvey) => {
      clickedSurvey.value = survey ?? null;
      displayDialog.value = true;
    };

    const openUpdateSurveyDialog = () => {
      displayDialog.value = true;
    };

    const closeSurveyDialog = (survey?: ChatSurvey) => {
      displayDialog.value = false;
      if (!survey) {
        return;
      }

      if (survey.uuid) {
        updateSurvey(survey);
        return;
      }
      sendSurvey(survey);
    };
    const startSurvey = (survey: ChatSurvey) => {
      store.dispatch(ActionTypes.START_SURVEY, survey);
    };
    const cancelSurvey = (survey: ChatSurvey) => {
      store.dispatch(ActionTypes.CANCEL_SURVEY, survey);
    };
    const editSurvey = (survey: ChatSurvey) => {
      openCreateSurveyDialog(survey);
    };
    const deleteSurvey = (survey: ChatSurvey) => {
      store.dispatch(ActionTypes.DELETE_SURVEY, survey);
    };

    onMounted(async () => {
      const store = useStore();
      store.dispatch(ActionTypes.FETCH_SURVEYS);
    });

    return {
      clickedSurvey,
      displayDialog,
      t: t,
      fetchedSurveys: computed(() => store.state.surveys),
      sendSurvey,
      updateSurvey,
      openUpdateSurveyDialog,
      closeSurveyDialog,
      startSurvey,
      cancelSurvey,
      editSurvey,
      deleteSurvey
    };
  }
});
