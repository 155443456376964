<template>
  <div class="user-item" :class="{ disconnected: user.isConnected }">
    <span class="name">{{
      user.usernameChanged ? user.name : t("message.user")
    }}</span>
    <span class="tag">#{{ user.tagNumber }}</span>

    <div v-if="user.isBanned" class="user-badge p-badge p-badge-danger">
      <i class="pi pi-ban"></i>
    </div>

    <div
      v-for="(tag, key) in getTags()"
      :key="key"
      class="user-badge p-badge p-badge-info"
    >
      {{ tag }}
    </div>

    <div v-if="user.isConnected" class="p-text-right">
      <i
        class="context-menu-icon pi pi-ellipsis-v p-pt-2"
        @click="openContextMenu($event)"
      />

      <ContextMenu ref="contextModMenu" :model="menuModel" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, watch } from "vue";
import { AnyObject, ChatUser, UserRole } from "@/store/types";
import ContextMenu from "primevue/contextmenu";
import { useI18n } from "vue-i18n";

const buildContextMenu = (props: AnyObject, t: Function) => {
  const user: ChatUser = props.user;
  const menu = [];

  if (user.isBanned) {
    menu.push({
      label: t("message.actionUnblockUser"),
      icon: "pi pi-fw pi-check-circle",
      command: () => {
        props.onUserUnblock(user);
      }
    });
  } else {
    menu.push({
      label: t("message.actionBlockUser"),
      icon: "pi pi-fw pi-ban",
      command: () => {
        props.onUserBlock(user);
      }
    });
  }

  return menu;
};

export default defineComponent({
  name: "UserItem",
  components: {
    ContextMenu: ContextMenu
  },
  props: {
    user: {
      type: Object as PropType<ChatUser>,
      required: true
    },

    onUserBlock: {
      type: Function as PropType<(user: ChatUser) => {}>,
      required: true
    },
    onUserUnblock: {
      type: Function as PropType<(user: ChatUser) => {}>,
      required: true
    }
  },
  setup: (props: AnyObject) => {
    const { t } = useI18n();
    const contextModMenu = ref<ContextMenu>();
    const menuModel = ref(buildContextMenu(props, t));

    watch(
      () => props.user,
      () => {
        menuModel.value = buildContextMenu(props, t);
      },
      { deep: true, flush: "post" }
    );

    return {
      t: t,
      contextModMenu: contextModMenu,
      menuModel: menuModel,

      openContextMenu(event: Event) {
        contextModMenu.value?.toggle(event);
      },
      getTags() {
        const tags = [];

        if (props.user.roles?.includes(UserRole.MODERATOR)) {
          tags.push("Moderator");
        }

        if (props.user.roles?.includes(UserRole.SPEAKER)) {
          tags.push("Speaker");
        }

        if (!props.user.isConnected) {
          tags.push(t("message.userDisconnected"));
        }

        return tags;
      }
    };
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../theme/variables.scss";
@import "../theme/mixins.scss";
.user-item {
  display: flex;
  align-items: center;
  padding: 20px 0;

  &.disconnected {
    color: $color-gray-dark;
  }

  .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 5px;

    @media (max-width: 320px) {
      font-size: $font-size-base;
    }
  }

  .tag {
    color: $color-gray-dark;
    font-size: $font-size-small;
  }

  .p-text-right {
    margin-left: auto;
  }

  .user-badge {
    @include userBagde;
  }
}
</style>
