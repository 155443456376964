<template>
  <h3>{{ t("message.topicsTabTitle") }}</h3>
  <div ref="scrollPanel" class="page topics-page">
    <ScrollPanel>
      <div v-for="(topic, key) in topics" :key="key">
        <ChatTopicItem
          :topic="topic"
          :user="user"
          :on-topic-start="startTopic"
          :on-topic-stop="stopTopic"
          :on-topic-delete="deleteTopic"
        ></ChatTopicItem>
      </div>
    </ScrollPanel>
  </div>
</template>

<script lang="ts">
import { useStore } from "@/store";
import { computed, defineComponent, onMounted } from "vue";
import { ActionTypes } from "@/store/actions";
import { useI18n } from "vue-i18n";
import ChatTopicItem from "@/components/ChatTopicItem.vue";
import { ChatTopic } from "@/store/types";
import ScrollPanel from "primevue/scrollpanel";

export default defineComponent({
  name: "TopicsPage",
  components: {
    ChatTopicItem: ChatTopicItem,
    ScrollPanel
  },
  setup() {
    const store = useStore();
    onMounted(async () => {
      await store.dispatch(ActionTypes.FETCH_TOPICS);
    });

    const { t } = useI18n();

    return {
      t: t,
      user: computed(() => store.getters.getUser),
      topics: computed(() => store.getters.getTopics),
      startTopic(topic: ChatTopic) {
        store.dispatch(ActionTypes.START_TOPIC, topic).then(() => {
          store.dispatch(ActionTypes.FETCH_TOPICS);
        });
      },
      stopTopic(topic: ChatTopic) {
        store.dispatch(ActionTypes.STOP_TOPIC, topic).then(() => {
          store.dispatch(ActionTypes.FETCH_TOPICS);
        });
      },
      deleteTopic(topic: ChatTopic) {
        store.dispatch(ActionTypes.DELETE_TOPIC, topic).then(() => {
          store.dispatch(ActionTypes.FETCH_TOPICS);
        });
      }
    };
  },
  methods: {}
});
</script>
<style scoped lang="scss">
@import "../../theme/variables.scss";
.topics-page {
  .p-scrollpanel {
    height: calc(100vh - 155px);
  }
  width: 100%;
}
</style>
