import { App } from "@vue/runtime-core";
export interface SocketPluginOptions {
  connection: string;
  options: Partial<SocketIOClient.ConnectOpts>;
}

export default {
  install: (app: App, socket: SocketIOClient.Socket) => {
    app.config.globalProperties.$socket = socket;
    app.provide("socket", socket);
  }
};

/**
 * Usage:
 * const socket = inject<SocketIOClient.Socket>("socket");
 */
