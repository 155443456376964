
import {
  ChatMessage,
  ChatMessageContextAction,
  ChatMessageState,
  UserRole
} from "@/store/types";
import { defineComponent, PropType, ref, watch } from "vue";
import UserAvatar from "./UserAvatar.vue";
import { useI18n } from "vue-i18n";
import InputSwitch from "primevue/inputswitch";
import { formatDate } from "@/utils/date";
import ChatContextMenu from "@/components/ChatContextMenu.vue";

export default defineComponent({
  name: "ChatMessage",
  components: {
    ChatContextMenu: ChatContextMenu,
    InputSwitch: InputSwitch,
    UserAvatar
  },
  props: {
    isContextMenu: {
      type: Boolean,
      default: false
    },
    isApproval: {
      type: Boolean,
      default: false
    },
    message: {
      type: Object as PropType<ChatMessage>,
      required: true
    },
    onMarkAsTopic: {
      type: Function as PropType<(message: ChatMessage) => void>,
      required: true
    },
    onBanMessage: {
      type: Function as PropType<(message: ChatMessage) => void>,
      required: true
    },
    onDeleteMessage: {
      type: Function as PropType<(message: ChatMessage) => void>,
      required: true
    },
    onBanUser: {
      type: Function as PropType<(message: ChatMessage) => void>,
      required: true
    },
    onApprove: {
      type: Function as PropType<(message: ChatMessage) => void>,
      required: true
    },
    onRevertApproval: {
      type: Function as PropType<(message: ChatMessage) => void>,
      required: true
    }
  },
  setup(props) {
    const { t } = useI18n();
    const isApproved = ref(props.message.state === ChatMessageState.ENABLED);

    watch(
      () => props.message,
      () => {
        isApproved.value = props.message.state === ChatMessageState.ENABLED;
      }
    );

    return {
      t: t,
      ChatMessageState: ChatMessageState,
      isApproved: isApproved,

      onAction: (action: ChatMessageContextAction, message: ChatMessage) => {
        switch (action) {
          case ChatMessageContextAction.APPROVE_MESSAGE:
            props.onApprove(message);
            return;
          case ChatMessageContextAction.REVERT_APPROVE_MESSAGE:
            props.onRevertApproval(message);
            return;
          case ChatMessageContextAction.MARK_TOPIC:
            props.onMarkAsTopic(message);
            return;
          case ChatMessageContextAction.BLOCK_MESSAGE:
            props.onBanMessage(message);
            return;
          case ChatMessageContextAction.UNBLOCK_MESSAGE:
            props.onApprove(message);
            return;
          case ChatMessageContextAction.BAN_USER:
            props.onBanUser(message);
            return;
          case ChatMessageContextAction.DELETE_MESSAGE:
            props.onDeleteMessage(message);
            return;
        }
      },
      formatTime(timeStamp: string) {
        return formatDate(new Date(parseInt(timeStamp)), "hh:mm");
      },
      getClasses() {
        return {
          blocked: props.message.state !== ChatMessageState.ENABLED,
          waiting: props.message.state === ChatMessageState.WAITING,
          "is-staff":
            props.message.user?.roles?.includes(UserRole.MODERATOR) ||
            props.message.user?.roles?.includes(UserRole.SPEAKER)
        };
      },

      getMessageText() {
        const message = props.message;

        if (props.isApproval) {
          return message.messageUncensored;
        }

        const visible = [ChatMessageState.ENABLED, ChatMessageState.WAITING];
        if (visible.includes(message.state as ChatMessageState)) {
          return message.message;
        }

        return t("message.messageWasBlocked");
      },
      approve() {
        isApproved.value = !isApproved.value;
        if (isApproved.value) {
          props.onApprove(props.message);
        } else {
          props.onRevertApproval(props.message);
        }
      }
    };
  }
});
