
import { defineComponent, PropType } from "vue";
import { ChatTopic, ChatTopicState, UserRole } from "@/store/types";
import Button from "primevue/button";
import Card from "primevue/card";
import { useI18n } from "vue-i18n";
import { formatDate } from "@/utils/date";

export default defineComponent({
  name: "ChatTopicItem",
  components: {
    Button,
    Card
  },
  props: {
    topic: {
      type: Object as PropType<ChatTopic>,
      required: true
    },
    onTopicStart: {
      type: Function as PropType<(topic: ChatTopic) => void>,
      required: true
    },
    onTopicStop: {
      type: Function as PropType<(topic: ChatTopic) => void>,
      required: true
    },
    onTopicDelete: {
      type: Function as PropType<(topic: ChatTopic) => void>,
      required: true
    }
  },
  setup: props => {
    const { t } = useI18n();
    const isModerator = props.topic.message?.user?.roles?.includes(
      UserRole.MODERATOR
    );

    return {
      t,
      ChatTopicState: ChatTopicState,
      isModerator,
      formatTime(timeStamp: string) {
        return formatDate(new Date(parseInt(timeStamp)), "hh:mm");
      },
      getClasses() {
        return {
          isModerator
        };
      },
      getBubbleColored() {
        const color = props.topic.message?.user;

        if (!color) {
          return "";
        }

        return "#" + color.colorHexNumber;
      }
    };
  },
  methods: {}
});
