
import { defineComponent, onMounted, PropType, ref, watch } from "vue";
import ScrollPanel from "primevue/scrollpanel";
import ChatMessage from "./ChatMessage.vue";
import ServerMessage from "./ServerMessage.vue";
import ErrorMessage from "./ErrorMessage.vue";
import { ChatMessage as ChatMessageType, Message } from "@/store/types";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "ChatHistory",
  components: {
    ScrollPanel,
    ChatMessage,
    ErrorMessage,
    ServerMessage
  },
  props: {
    hasModOrSpeakerRole: {
      type: Boolean,
      required: true
    },
    isApprovalPageActive: {
      type: Boolean,
      required: true
    },
    messages: {
      type: Array as PropType<Message[]>,
      default: () => [],
      required: true
    },
    approvalMessages: {
      type: Array as PropType<Message[]>,
      default: () => [],
      required: true
    },
    onMarkMessageAsTopic: {
      type: Function as PropType<(message: ChatMessageType) => void>,
      required: true
    },
    onDeleteMessage: {
      type: Function as PropType<(message: ChatMessageType) => void>,
      required: true
    },
    onBanMessage: {
      type: Function as PropType<(message: ChatMessageType) => void>,
      required: true
    },
    onBanUser: {
      type: Function as PropType<(message: ChatMessageType) => void>,
      required: true
    },
    onApprove: {
      type: Function as PropType<(message: ChatMessageType) => void>,
      required: true
    },
    onRevertApproval: {
      type: Function as PropType<(message: ChatMessageType) => void>,
      required: true
    }
  },
  setup: props => {
    const { t } = useI18n();
    const isScrolledTop = ref(true);
    const isScrolledBottom = ref(false);
    const scrollPanel = ref<HTMLDivElement>();

    const getScrollContent = () => {
      if (!scrollPanel.value) {
        return null;
      }

      return scrollPanel.value.querySelector<HTMLDivElement>(
        ".p-scrollpanel-content"
      );
    };

    const onScrolledToBottomListener = () => {
      const content = getScrollContent();
      if (!content) {
        return;
      }

      content.onscroll = e => {
        const elem: HTMLElement = e.target as HTMLElement;
        const current = elem?.scrollTop;
        const max = elem?.scrollHeight - elem?.offsetHeight;
        const acceptanceRange = 20;

        if (current === 0) {
          isScrolledTop.value = true;
        } else if (isScrolledTop.value) {
          isScrolledTop.value = false;
        }

        const acceptedMax = max - acceptanceRange;
        if (current >= acceptedMax) {
          isScrolledBottom.value = true;
        } else if (isScrolledBottom.value) {
          isScrolledBottom.value = false;
        }
      };
    };

    const scrollToBottom = () => {
      const content = getScrollContent();
      if (!content) {
        return;
      }

      content.scrollTop = content.scrollHeight;
      isScrolledBottom.value = true;
      isScrolledTop.value = content.scrollTop === 0;
    };

    const getMessageType = (message: Message) => {
      if (!message.type || message.type === "chat") {
        return ChatMessage;
      }
      if (message.type === "server") {
        return ServerMessage;
      }
      if (message.type === "error") {
        return ErrorMessage;
      }
      return ChatMessage;
    };

    const isShowScrollDownButton = () => {
      if (props.isApprovalPageActive) {
        return false;
      }

      return isScrolledBottom.value === false;
    };

    const isAutoScrollEnabled = () => {
      if (props.isApprovalPageActive) {
        return false;
      }

      return isScrolledBottom.value;
    };

    onMounted(async () => {
      scrollToBottom();
      onScrolledToBottomListener();
    });

    // scroll down on tab switch cause of different messages length
    watch(
      () => props.isApprovalPageActive,
      () => scrollToBottom(),
      { deep: true, flush: "post" }
    );

    // scroll down when messages are changing (new messages received)
    watch(
      () => props.messages,
      () => {
        // do not scroll down when the mod is moderating
        if (!isAutoScrollEnabled()) {
          return;
        }
        scrollToBottom();
      },
      { deep: true, flush: "post" }
    );

    const getChatHistoryClasses = () => {
      return {
        "chat-history": true,
        "is-top": isScrolledTop.value,
        hastabs: props.hasModOrSpeakerRole
      };
    };

    return {
      t,
      scrollPanel,
      scrollToBottom,
      isScrolledTop,
      getMessageType,
      isShowScrollDownButton,
      getChatHistoryClasses,
      getMessages() {
        if (props.hasModOrSpeakerRole && props.isApprovalPageActive) {
          return props.approvalMessages;
        }
        return props.messages;
      }
    };
  }
});
