import { MutationTree } from "vuex";
import { RootState } from ".";
import {
  ChatMessage,
  ChatRoom,
  ChatSurvey,
  ChatTopic,
  ChatUser,
  FlashMessage,
  ServerMessage
} from "./types";

export enum MutationTypes {
  ADD_FLASH_MESSAGE = "ADD_FLASH_MESSAGE",
  REMOVE_FLASH_MESSAGE = "REMOVE_FLASH_MESSAGE",
  SET_IS_LOADING = "SET_IS_LOADING",
  SET_LOGGED_IN = "SET_LOGGED_IN",
  RESET_AUTH = "RESET_AUTH",
  SET_DESIRED_ROOM = "SET_DESIRED_ROOM",
  SET_LOGIN_CODE = "SET_LOGIN_CODE",
  SET_LOGIN_TOKEN = "SET_LOGIN_TOKEN",
  RESET_LOGIN_TOKEN = "RESET_LOGIN_TOKEN",
  SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN",
  RESET_ACCESS_TOKEN = "RESET_ACCESS_TOKEN",
  SET_ASK_FOR_LOGIN_CODE = "SET_ASK_FOR_LOGIN_CODE",
  SET_USER = "SET_USER",
  SET_PROFILE_ERROR = "SET_PROFILE_ERROR",
  SET_PROFILE_SAVING = "SET_PROFILE_SAVING",
  SET_CHAT_CONNECTED = "SET_CHAT_CONNECTED",
  SET_ACTIVE_MESSAGE_CONTEXT_MENU = "SET_ACTIVE_MESSAGE_CONTEXT_MENU",
  SET_CHAT_ACTIVE_ROOM = "SET_CHAT_ACTIVE_ROOM",
  SET_ACTIVE_SURVEY = "SET_ACTIVE_SURVEY",
  SET_SURVEYS = "SET_SURVEYS",
  SET_SURVEYS_REMOTE_VIEW = "SET_SURVEYS_REMOTE_VIEW",
  SET_SERVER_MESSAGES = "SET_SERVER_MESSAGES",
  ADD_SERVER_MESSAGE = "ADD_SERVER_MESSAGE",
  SET_MESSAGES = "SET_MESSAGES",
  SET_MESSAGE = "SET_MESSAGE",
  UPDATE_MESSAGE = "UPDATE_MESSAGE",
  UPDATE_USER_MESSAGES = "UPDATE_USER_MESSAGES",
  SET_CURRENT_MESSAGE = "SET_CURRENT_MESSAGE",
  RESET_CURRENT_MESSAGE = "RESET_CURRENT_MESSAGE",
  SET_ACTIVE_USERS = "SET_ACTIVE_USERS",
  UPDATE_ACTIVE_USER = "UPDATE_ACTIVE_USER",
  SET_TOPICS = "SET_TOPICS",
  SET_ACTIVE_TOPIC = "SET_ACTIVE_TOPIC",
  UPDATE_SURVEY = "UPDATE_SURVEY",
  DELETE_SURVEY = "DELETE_SURVEY",
  UPDATE_TOPIC = "UPDATE_TOPIC",
  DELETE_TOPIC = "DELETE_TOPIC"
}

export type Mutations<S = RootState> = {
  [MutationTypes.ADD_FLASH_MESSAGE](state: S, payload: FlashMessage): void;
  [MutationTypes.REMOVE_FLASH_MESSAGE](state: S, payload: number): void;
  [MutationTypes.SET_IS_LOADING](state: S, payload: boolean): void;
  [MutationTypes.SET_LOGGED_IN](state: S, payload: boolean): void;
  [MutationTypes.RESET_AUTH](state: S): void;
  [MutationTypes.SET_DESIRED_ROOM](state: S, payload: string): void;
  [MutationTypes.SET_LOGIN_CODE](state: S, payload: string): void;
  [MutationTypes.SET_LOGIN_TOKEN](state: S, payload: string): void;
  [MutationTypes.RESET_LOGIN_TOKEN](state: S): void;
  [MutationTypes.SET_ACCESS_TOKEN](state: S, payload: string): void;
  [MutationTypes.RESET_ACCESS_TOKEN](state: S): void;
  [MutationTypes.SET_ASK_FOR_LOGIN_CODE](state: S, payload: boolean): void;
  [MutationTypes.SET_USER](state: S, payload: ChatUser): void;
  [MutationTypes.SET_PROFILE_ERROR](state: S, payload: boolean): void;
  [MutationTypes.SET_PROFILE_SAVING](state: S, payload: boolean): void;
  [MutationTypes.SET_CHAT_CONNECTED](state: S, payload: boolean): void;
  [MutationTypes.SET_ACTIVE_MESSAGE_CONTEXT_MENU](
    state: S,
    payload: string
  ): void;
  [MutationTypes.SET_CHAT_ACTIVE_ROOM](state: S, payload: ChatRoom): void;
  [MutationTypes.SET_ACTIVE_SURVEY](state: S, payload: ChatSurvey | null): void;
  [MutationTypes.SET_SURVEYS](state: S, payload: ChatSurvey[]): void;
  [MutationTypes.SET_SURVEYS_REMOTE_VIEW](
    state: S,
    payload: ChatSurvey[]
  ): void;
  [MutationTypes.SET_SERVER_MESSAGES](state: S, payload: ServerMessage[]): void;
  [MutationTypes.ADD_SERVER_MESSAGE](state: S, payload: ServerMessage): void;
  [MutationTypes.SET_MESSAGES](state: S, payload: ChatMessage[]): void;
  [MutationTypes.SET_MESSAGE](state: S, payload: ChatMessage): void;
  [MutationTypes.UPDATE_MESSAGE](state: S, payload: ChatMessage): void;
  [MutationTypes.UPDATE_USER_MESSAGES](state: S, payload: ChatUser): void;
  [MutationTypes.SET_CURRENT_MESSAGE](state: S, payload: string): void;
  [MutationTypes.RESET_CURRENT_MESSAGE](state: S, payload?: string): void;
  [MutationTypes.SET_ACTIVE_USERS](state: S, payload: ChatUser[]): void;
  [MutationTypes.UPDATE_ACTIVE_USER](state: S, payload: ChatUser): void;
  [MutationTypes.SET_TOPICS](state: S, payload: ChatTopic[]): void;
  [MutationTypes.SET_ACTIVE_TOPIC](state: S, payload?: ChatTopic): void;
  [MutationTypes.UPDATE_SURVEY](state: S, payload: ChatSurvey): void;
  [MutationTypes.DELETE_SURVEY](state: S, payload: ChatSurvey): void;
  [MutationTypes.UPDATE_TOPIC](state: S, payload: ChatTopic): void;
  [MutationTypes.DELETE_TOPIC](state: S, payload: ChatTopic): void;
};

const mutations: MutationTree<RootState> & Mutations = {
  [MutationTypes.ADD_FLASH_MESSAGE]: (state, payload) => {
    state.flashMessages = [...state.flashMessages, payload];
  },
  [MutationTypes.REMOVE_FLASH_MESSAGE]: (state, payload) => {
    state.flashMessages.splice(payload, 1);
  },
  [MutationTypes.SET_IS_LOADING]: (state, payload) => {
    state.isLoading = payload;
  },
  [MutationTypes.SET_LOGGED_IN]: (state, payload) => {
    state.isLoggedIn = payload;
  },
  [MutationTypes.RESET_AUTH]: state => {
    state.isLoggedIn = false;
    state.loginToken = undefined;
    state.user = undefined;
    state.accessToken = undefined;
    state.activeRoom = undefined;
  },
  [MutationTypes.SET_DESIRED_ROOM]: (state, payload) => {
    state.desiredRoomUuid = payload;
  },
  [MutationTypes.SET_LOGIN_CODE]: (state, payload) => {
    state.loginCode = payload;
  },
  [MutationTypes.SET_LOGIN_TOKEN]: (state, payload) => {
    state.loginToken = payload;
  },
  [MutationTypes.RESET_LOGIN_TOKEN]: state => {
    state.loginToken = undefined;
  },
  [MutationTypes.SET_ACCESS_TOKEN]: (state, payload) => {
    state.accessToken = payload;
  },
  [MutationTypes.RESET_ACCESS_TOKEN]: state => {
    state.accessToken = undefined;
  },
  [MutationTypes.SET_ASK_FOR_LOGIN_CODE]: (state, payload) => {
    state.askForLoginCode = payload;
  },
  [MutationTypes.SET_USER]: (state, payload) => {
    state.user = payload;
  },
  [MutationTypes.SET_PROFILE_ERROR]: (state, payload) => {
    state.profileError = payload;
  },
  [MutationTypes.SET_PROFILE_SAVING]: (state, payload) => {
    state.profileSaving = payload;
  },
  [MutationTypes.SET_CHAT_CONNECTED]: (state, payload) => {
    state.socketConnected = payload;
  },
  [MutationTypes.SET_ACTIVE_MESSAGE_CONTEXT_MENU]: (state, payload) => {
    state.activeMessageContextMenu = payload;
  },
  [MutationTypes.SET_CHAT_ACTIVE_ROOM]: (state, payload) => {
    state.activeRoom = payload;
  },
  [MutationTypes.SET_ACTIVE_SURVEY]: (state, payload) => {
    state.activeSurvey = payload ?? undefined;
  },
  [MutationTypes.SET_SURVEYS]: (state, payload) => {
    state.surveys = payload;
  },
  [MutationTypes.SET_SURVEYS_REMOTE_VIEW]: (state, payload) => {
    state.surveysRemoteView = payload;
  },
  [MutationTypes.SET_SERVER_MESSAGES]: (state, payload) => {
    state.serverMessages = payload;
  },
  [MutationTypes.ADD_SERVER_MESSAGE]: (state, payload) => {
    state.serverMessages.push(payload);
  },
  [MutationTypes.SET_MESSAGES]: (state, payload) => {
    state.messages = payload;
  },
  [MutationTypes.SET_MESSAGE]: (state, payload) => {
    state.messages.push(payload);
  },
  [MutationTypes.UPDATE_MESSAGE]: (state, payload) => {
    state.messages = state.messages.map(m => {
      if (m.uuid === payload.uuid) {
        return payload;
      }

      return m;
    });
  },
  [MutationTypes.UPDATE_USER_MESSAGES]: (state, payload) => {
    state.messages = state.messages.map((m: ChatMessage) => {
      if (m.user?.uuid === payload.uuid) {
        m.user = payload;
      }

      return m;
    });
  },
  [MutationTypes.SET_CURRENT_MESSAGE]: (state, payload) => {
    state.currentMessage = payload;
  },
  [MutationTypes.RESET_CURRENT_MESSAGE]: state => {
    state.currentMessage = undefined;
  },
  [MutationTypes.SET_ACTIVE_USERS]: (state, payload) => {
    state.activeUsers = payload;
  },
  [MutationTypes.UPDATE_ACTIVE_USER]: (state, payload) => {
    state.activeUsers = state.activeUsers.map(u => {
      if (u.uuid === payload.uuid) {
        return payload;
      }

      return u;
    });
  },
  [MutationTypes.SET_TOPICS]: (state, payload) => {
    state.topics = payload;
  },
  [MutationTypes.SET_ACTIVE_TOPIC]: (state, payload) => {
    state.activeTopic = payload;
  },
  [MutationTypes.UPDATE_SURVEY]: (state, payload) => {
    if (!payload) {
      return;
    }

    let isNew = true;
    const surveys: ChatSurvey[] = state.surveys ?? [];
    state.surveys = surveys.map(s => {
      if (s.uuid === payload?.uuid) {
        isNew = false;
        return payload;
      }

      return s;
    });

    if (isNew) {
      state.surveys.push(payload);
    }
  },
  [MutationTypes.DELETE_SURVEY]: (state, payload) => {
    if (!payload) {
      return;
    }

    state.surveys = state.surveys.filter(s => {
      return s.uuid !== payload.uuid;
    });
  },
  [MutationTypes.UPDATE_TOPIC]: (state, payload) => {
    if (!payload) {
      return;
    }

    let isNew = true;
    state.topics = state.topics.map(t => {
      if (t.uuid === payload.uuid) {
        isNew = false;
        return payload;
      }

      return t;
    });
    if (isNew) {
      state.topics.push(payload);
    }
  },
  [MutationTypes.DELETE_TOPIC]: (state, payload) => {
    if (!payload) {
      return;
    }

    state.topics = state.topics.filter(t => {
      return t.uuid !== payload.uuid;
    });
  }
};

export default mutations;
