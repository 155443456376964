
import { defineComponent, PropType } from "vue";
import { ChatSurvey } from "@/store/types";
import ChatSurveyItem from "@/components/ChatSurveyItem.vue";
import ScrollPanel from "primevue/scrollpanel";
import Button from "primevue/button";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "ChatSurveyList",
  components: {
    ChatSurveyItem,
    ScrollPanel,
    Button
  },
  inheritAttrs: false,
  props: {
    surveys: {
      type: Array as PropType<ChatSurvey[]>,
      default: () => []
    },

    onSurveyStart: {
      type: Function as PropType<(survey: ChatSurvey) => {}>,
      required: true
    },
    onSurveyCancel: {
      type: Function as PropType<(survey: ChatSurvey) => {}>,
      required: true
    },
    onSurveyEdit: {
      type: Function as PropType<(survey: ChatSurvey) => {}>,
      required: true
    },
    onSurveyDelete: {
      type: Function as PropType<(survey: ChatSurvey) => {}>,
      required: true
    }
  },
  setup: () => {
    const { t } = useI18n();
    return {
      t
    };
  }
});
