<template>
  <Toast class="flash-message" position="top-center"></Toast>

  <ProgressSpinner v-if="isLoading" class="progress-spinner" />

  <LoginPage v-if="!isLoggedIn" />

  <section v-if="isLoggedIn" class="page-content">
    <!--  PAGE CHAT  -->
    <div v-if="page === 'chat' || page === 'approval'">
      <SettingsPanel
        :current-user="user"
        :on-logout="logout"
        :current-chat-mode="currentChatMode"
        :is-chat-active="isChatActive"
        :on-setting-changed="settingChanged"
        :on-open-survey-remote-view="goToRemoteSurveys"
        :on-clear-room="clearChatRoom"
      ></SettingsPanel>
      <ChatPage :is-approval="page === 'approval'" />
    </div>

    <!--  MOD PAGE SURVEY  -->
    <SurveysPage v-if="page === 'surveys'" />

    <!--  MOD PAGE TOPICS  -->
    <TopicsPage v-if="page === 'topics'" />

    <!--  MOD PAGE USERS  -->
    <UsersPage v-if="page === 'users'" />

    <!--  MOD PAGE REMOTE SURVEY (Monitor)  -->
    <RemoteSurveysPage v-if="page === 'remote_surveys'" />

    <footer :class="{ 'footer-chat': true, mod: isModMode }">
      <ChatSubmitForm
        v-if="isLoggedIn && (page === 'chat' || page === 'approval')"
        :is-enabled="isChatInputAllowed()"
        :is-chat-active="isChatActive"
        :submit-message="sendMessage"
        :current-message="currentMessage"
        :update-current-message="updateCurrentMessage"
      />

      <ModSwitch
        v-if="isLoggedIn"
        :is-active="isModSwitch()"
        :is-not-mod-admin="!isLoggedIn"
        @page-changed="changePage"
      />
    </footer>
  </section>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import { useStore } from "@/store";
import ProgressSpinner from "primevue/progressspinner";

import LoginPage from "@/pages/LoginPage/LoginPage.vue";
import ChatPage from "@/pages/ChatPage/ChatPage.vue";
import SurveysPage from "@/pages/SurveysPage/SurveysPage.vue";
import TopicsPage from "@/pages/TopicsPage/TopicsPage.vue";

import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./theme/overrides.scss";
import ModSwitch from "@/components/ModSwitch.vue";
import {
  ChatRoomMode,
  FlashMessage,
  PageType,
  SettingKey,
  SettingsEntry,
  UserRole
} from "@/store/types";
import ChatSubmitForm from "@/components/ChatSubmitForm.vue";
import { ActionTypes } from "@/store/actions";
import { MutationTypes } from "@/store/mutations";
import UsersPage from "@/pages/UsersPage/UsersPage.vue";
import RemoteSurveysPage from "@/pages/RemoteSurveysPage/RemoteSurveysPage.vue";
import SettingsPanel from "@/components/SettingsPanel.vue";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "App",
  components: {
    ProgressSpinner,
    LoginPage,
    ChatPage,
    SurveysPage,
    RemoteSurveysPage,
    TopicsPage,
    UsersPage,
    ChatSubmitForm,
    SettingsPanel,
    Toast,
    ModSwitch
  },
  setup: () => {
    const store = useStore();
    const currentUser = computed(() => store.getters.getUser);
    const isChatActive = computed(() => {
      return !!store.getters.getActiveRoom?.isActive;
    });

    const page = ref<PageType>(PageType.CHAT);

    const { t } = useI18n();
    const toast = useToast();

    const flashMessages = computed(() => store.state.flashMessages);
    watch(flashMessages, () => {
      const messages: FlashMessage[] = flashMessages.value;
      if (!messages || !messages.length) {
        return;
      }

      messages.forEach((m, key) => {
        const message = m.messagePlain ?? t(m.message);
        toast.add({
          severity: m.type,
          summary: m.title ? t(m.title) : "",
          detail: message ?? "",
          life: m.duration ?? 4000
        });
        store.commit(MutationTypes.REMOVE_FLASH_MESSAGE, key);
        if (typeof m.onShown !== "undefined") {
          setTimeout(() => {
            m.onShown && m.onShown();
          }, m.duration);
        }
      });
    });

    const changePage = (p: PageType) => {
      page.value = p;
    };

    const isModSwitch = () => {
      if (!currentUser.value || !currentUser.value.roles) {
        return false;
      }

      const roles = currentUser.value.roles;
      if (roles.includes(UserRole.SPEAKER)) {
        return true;
      }

      if (roles.includes(UserRole.MODERATOR)) {
        return true;
      }

      return false;
    };

    watch(
      () => store.getters.getIsloggedIn,
      () => {
        if (
          store.getters.getIsloggedIn &&
          window.location.href.indexOf("remote") >= 0
        ) {
          const isMod =
            currentUser.value?.roles.includes(UserRole.SPEAKER) ||
            currentUser.value?.roles.includes(UserRole.MODERATOR);

          if (isMod) {
            changePage(PageType.REMOTE_SURVEYS);
          }
        }
      }
    );

    return {
      page: page,
      user: currentUser,
      isChatActive: isChatActive,
      isLoading: computed(() => store.getters.getIsLoading),
      isLoggedIn: computed(() => store.getters.getIsloggedIn),
      isModMode: computed(() => store.getters.getIsModMode),
      currentChatMode: computed(() => store.getters.getCurrentChatMode),
      currentMessage: computed(() => store.state.currentMessage || ""),
      changePage: changePage,
      isModSwitch: isModSwitch,

      logout: () => {
        store.dispatch(ActionTypes.LOGOUT);
      },
      isChatInputAllowed: () => {
        return !currentUser.value?.isBanned;
      },
      updateCurrentMessage: (message: string) => {
        store.commit(MutationTypes.SET_CURRENT_MESSAGE, message);
      },
      settingChanged: (setting: SettingsEntry) => {
        if (setting.key == SettingKey.CHAT_MODE) {
          store.dispatch(
            ActionTypes.UPDATE_CHAT_MODE,
            setting.value as ChatRoomMode
          );
        }
        if (setting.key == SettingKey.CHAT_ACTIVATION_STATE) {
          store.dispatch(
            ActionTypes.UPDATE_CHAT_ACTIVATION_STATE,
            setting.value as boolean
          );
        }
      },
      goToRemoteSurveys: () => {
        const remoteSurveyUrl =
          window.location.href +
          (window.location.href.indexOf("?") >= 0 ? "&remote" : "?remote");

        window.open(remoteSurveyUrl, "_blank");
      },
      clearChatRoom: () => {
        store.dispatch(ActionTypes.DELETE_MESSAGES);
      },
      sendMessage: () => {
        if (!store.state.currentMessage) return;
        store.dispatch(ActionTypes.SEND_MESSAGE, {
          message: store.state.currentMessage
        });
      }
    };
  }
});
</script>

<style lang="scss">
@import "./theme/variables.scss";

html,
body {
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;

  overflow: hidden;

  border-radius: $border-radius-extra-large;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  /* font-size: 62.5%; */
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: $color-black;
}
#chat-app {
  min-width: 320px;
  background-color: var(--surface-b);
  border-radius: $border-radius-extra-large;
  padding: 17px;
  box-sizing: border-box;
  min-height: 100vh;
  position: relative;
  max-width: 2000px;
  margin: 0 auto;

  .progress-spinner {
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--surface-0);
    opacity: 0.8;
    svg {
      width: 25%;
    }
  }

  section.page-content {
    display: flex;
    flex-flow: row nowrap;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    align-items: stretch;
    .settings-button {
      position: absolute;
      top: 18px;
      right: 15px;
      z-index: 1500;

      @media (min-width: 375px) {
        right: 20px;
        top: 20px;
      }

      .p-button.p-button-icon-only {
        width: 1.5rem;
        @media (min-width: 375px) {
          width: 2.357rem;
        }
      }
    }
    .page {
      width: 100%; /* required */
      flex-grow: 0.4; // was 0.6
    }

    .footer-chat {
      width: 89%;
      flex-basis: 100px;
      position: absolute;
      bottom: 50px;
      margin-top: 5px;
      &.mod {
        flex-basis: 115px;
        bottom: 100px;
      }
    }
  }
}

.p-toast.flash-message {
  width: 18rem;
  padding: 15px;
  position: absolute;
  right: 8%;
  left: 6%;
  top: 45px;
  border-radius: $border-radius-overlay;
  margin: 0 auto;
  opacity: 1;

  .p-toast-message {
    &.p-toast-message-success {
      background-color: $color-toast-message-success-bg;
      color: $color-toast-message-success-text;
      border: none;
    }

    &.p-toast-message-warn {
      background-color: $color-toast-message-warning-bg;
      color: $color-toast-message-warning-text;
      border: none;
    }

    &.p-toast-message-error {
      background-color: $color-toast-message-error-bg;
      color: $color-toast-message-error-text;
      border: none;
    }

    &.p-toast-message-info {
      background-color: $color-toast-message-info-bg;
      color: $color-toast-message-info-text;
      border: none;
    }
  }
}
.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon {
  color: $color-toast-message-success-text !important;
}
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
  color: $color-toast-message-success-text !important;
}
.p-component,
.p-inputtext {
  font-family: "Roboto";
}
</style>
