
import { defineComponent, PropType, ref } from "vue";
import { useI18n } from "vue-i18n";
import OverlayPanel from "primevue/overlaypanel";
import InputSwitch from "primevue/inputswitch";
import Button from "primevue/button";
import {
  ChatRoomMode,
  ChatUser,
  SettingKey,
  SettingsEntry,
  UserRole
} from "@/store/types";
import { useConfirm } from "primevue/useconfirm";

export default defineComponent({
  name: "SettingsPanel",
  components: {
    OverlayPanel,
    InputSwitch,
    Button
  },
  props: {
    currentUser: {
      type: Object as PropType<ChatUser>,
      required: true
    },
    currentChatMode: {
      type: String as PropType<ChatRoomMode>,
      default: ChatRoomMode.NORMAL
    },
    isChatActive: {
      type: Boolean,
      required: true
    },
    onSettingChanged: {
      type: Function as PropType<(entry: SettingsEntry) => void>,
      required: true
    },
    onLogout: {
      type: Function as PropType<() => void>,
      required: true
    },
    onOpenSurveyRemoteView: {
      type: Function as PropType<() => void>,
      required: true
    },
    onClearRoom: {
      type: Function as PropType<() => void>,
      required: true
    }
  },
  setup(props) {
    const { t } = useI18n();
    const confirm = useConfirm();
    const settingsPanel = ref<OverlayPanel>();

    const confirmLogout = () => {
      confirm.require({
        group: "confirmation",
        message: t("message.confirmLogout"),
        header: t("message.titleConfirmation"),
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        acceptLabel: t("message.confirmYes"),
        rejectLabel: t("message.confirmNo"),
        accept: () => {
          props.onLogout();
        }
      });
    };

    const confirmChatClear = () => {
      confirm.require({
        group: "extra-check",
        message: t("message.confirmChatClear"),
        header: t("message.titleConfirmation"),
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        acceptLabel: t("message.confirmYes"),
        rejectLabel: t("message.confirmNo"),
        accept: () => {
          props.onClearRoom();
        }
      });
    };

    const hasRole = (role: UserRole) => {
      if (!props.currentUser.roles) {
        return false;
      }

      return props.currentUser.roles.includes(role);
    };

    return {
      ChatRoomMode: ChatRoomMode,
      t: t,
      settingsPanel: settingsPanel,
      hasRole: hasRole,
      confirmLogout: confirmLogout,
      confirmChatClear: confirmChatClear,
      isMod: () => {
        return hasRole(UserRole.SPEAKER) || hasRole(UserRole.MODERATOR);
      },
      toggle: (event: Event) => {
        settingsPanel.value?.toggle(event);
      },
      toggleChatActiveState: (isActive: boolean) => {
        console.log("toggle", isActive);
        props.onSettingChanged({
          key: SettingKey.CHAT_ACTIVATION_STATE,
          value: isActive
        });
      },
      changedChatMode: (isAutoApproval: boolean) => {
        const mode: ChatRoomMode = isAutoApproval
          ? ChatRoomMode.STRICT
          : ChatRoomMode.NORMAL;

        props.onSettingChanged({
          key: SettingKey.CHAT_MODE,
          value: mode
        });
      },
      openSurveyRemoteView: () => {
        settingsPanel.value?.hide();
        props.onOpenSurveyRemoteView();
      }
    };
  }
});
