<template>
  <div v-if="askForLoginCode">
    <h3>{{ t("message.enterLoginCodeHere") }}</h3>

    <div class="enter">
      <InputText
        class="code-enter-input p-mt-2"
        placeholder="XXXXXX"
        @input="updateCode"
      />

      <Button
        class="p-mt-2 p-button p-button p-button-info login-button"
        :label="t('message.joinChatRoom')"
        :disabled="!codeIsValid()"
        icon="pi pi-sign-in"
        @click="loginByCode"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "@/store";
import { ActionTypes } from "@/store/actions";
import { useI18n } from "vue-i18n";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { MutationTypes } from "@/store/mutations";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "primevue/usetoast";

export default defineComponent({
  name: "LoginPage",
  components: {
    InputText: InputText,
    Button: Button
  },
  setup: () => {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const code = ref<string>("");
    const toast = useToast();

    onMounted(async () => {
      await store.dispatch(ActionTypes.FIND_AND_SET_LOGIN_TOKEN).then(() => {
        const query = Object.assign({}, route.query);
        delete query.mvclt;
        delete query.mvcltd;
        delete query.r;
        router.replace({ query });
      });

      if (!store.state.isLoggedIn && !store.state.askForLoginCode) {
        await store.dispatch(ActionTypes.LOGIN);
        await store.dispatch(ActionTypes.FETCH_USER);
      }
    });

    return {
      t: t,
      code: code,
      askForLoginCode: computed(() => store.state.askForLoginCode),
      codeIsValid() {
        return ("" + code.value).length >= 5;
      },
      updateCode(c: InputEvent) {
        const t = (c.target as HTMLInputElement) ?? { value: "" };
        code.value = t.value;
      },
      loginByCode() {
        store.commit(MutationTypes.SET_LOGIN_CODE, code.value);
        store
          .dispatch(ActionTypes.LOGIN)
          .then(() => {
            store.dispatch(ActionTypes.FETCH_USER);
          })
          .catch(e => {
            toast.add({
              severity: "error",
              summary: t("message.error"),
              detail: t(e.response.data.message),
              life: 4000
            });
          });
      }
    };
  },
  methods: {}
});
</script>

<style lang="scss">
@import "../../theme/variables.scss";

.enter {
  @media (min-width: 375px) {
    input,
    button {
      margin: 5px;
    }
  }
  input {
    width: 100%;
    border-radius: $border-radius-large;
    @media (min-width: 768px) {
      width: auto;
    }
  }
  button {
    border-radius: 4px;
  }

  .login-button {
    .p-button-label {
      @media (max-width: 370px) {
        margin-left: 0.5rem;
      }
    }
  }
}
.code-enter-input {
  text-transform: uppercase;

  &::placeholder {
    text-transform: none;
  }
}
.p-inputtext.p-component.code-enter-input.p-mt-2 {
  &:hover {
    background-color: $color-white;
    border-color: $color-btn-blue-normal;
  }
}
.p-mt-2.p-button.p-button.p-button-success {
  border-color: $color-green-normal;
}
.p-toast
  .p-toast-message.p-toast-message-error
  .p-toast-message-content
  .p-toast-icon-close.p-link
  .p-toast-icon-close-icon {
  color: $color-toast-message-error-text !important;
}
</style>
