
import { ErrorMessage } from "@/store/types";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "ErrorMessage",
  props: {
    message: {
      type: Object as PropType<ErrorMessage>,
      required: true
    }
  }
});
