<template>
  <div class="active-survey">
    <div v-if="!survey.statistic" class="header">
      <i class="survey-icon pi pi-chart-bar"></i>
      <span class="active-survey-title">
        {{
          survey.type === SurveyType.MULTIPLE_CHOICE
            ? t("message.surveyChooseAnswer")
            : t("message.surveyTypeAnswer")
        }}
      </span>
    </div>

    <section class="body">
      <div v-if="survey.question" class="active-question p-col">
        {{ survey.question }}
      </div>
      <SurveyAnswerForm
        v-if="!survey.statistic"
        :survey="survey"
        :on-answered="onAnswered"
        :on-cancel="onCancel"
      />

      <SurveyStatistics
        v-if="survey.statistic"
        :survey-type="survey.type"
        :survey-options="survey.options"
        :survey-stats="survey.statistic"
      />
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { ChatSurvey, SurveyType } from "@/store/types";
import SurveyStatistics from "@/components/SurveyStatistics.vue";
import SurveyAnswerForm from "@/components/SurveyAnswerForm.vue";

export default defineComponent({
  name: "ActiveSurvey",
  components: {
    SurveyStatistics,
    SurveyAnswerForm
  },
  props: {
    survey: {
      type: Object as PropType<ChatSurvey>,
      required: true
    },
    onAnswered: {
      type: Function as PropType<(survey: ChatSurvey, answer: string) => void>,
      required: true
    },
    onCancel: {
      type: Function as PropType<() => void>,
      required: false,
      default: undefined
    }
  },
  setup: () => {
    const { t } = useI18n();
    return {
      t,
      SurveyType
    };
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../theme/variables.scss";

.active-survey {
  background-color: $color-background-active-survey;
  padding: 14px;
  box-shadow: 0 0 20px rgb(0 0 0 / 30%);
  border: none;
  border-radius: $border-radius-overlay;
  margin: 0;
  top: 100px;

  max-width: 1000px;
  margin: 0 auto;
  z-index: 90;

  @media (max-width: 320px) {
    right: 25px;
  }
  @media (min-width: 768px) {
    right: 62px;
  }

  .survey-icon {
    font-size: $font-size-small-medium;
    color: $color-white;
    background: $color-btn-blue-normal;
    border-radius: $border-radius;
    padding: 7px;
    margin-right: 10px;

    @media (min-width: 375px) {
      font-size: $font-size-medium;
    }
  }
}

.header {
  .active-survey-title {
    font-weight: 600;
    font-size: $font-size-small-medium;
    @media (min-width: 375px) {
      font-size: $font-size-medium;
    }
  }
}

.active-question {
  font-weight: bold;
  word-break: break-word;
  hyphens: auto;
}
</style>
