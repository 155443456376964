
import { defineComponent, PropType } from "vue";
import { ChatSurvey } from "@/store/types";
import Button from "primevue/button";
import Card from "primevue/card";
import SurveyStatistics from "@/components/SurveyStatistics.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "ChatSurveyItem",
  components: {
    Button,
    SurveyStatistics,
    Card
  },
  props: {
    survey: {
      type: Object as PropType<ChatSurvey>,
      required: true
    },
    onSurveyStart: {
      type: Function as PropType<(surveys: ChatSurvey) => void>,
      required: true
    },
    onSurveyCancel: {
      type: Function as PropType<(surveys: ChatSurvey) => void>,
      required: true
    },
    onSurveyEdit: {
      type: Function as PropType<(surveys: ChatSurvey) => void>,
      required: true
    },
    onSurveyDelete: {
      type: Function as PropType<(surveys: ChatSurvey) => void>,
      required: true
    }
  },
  setup: () => {
    const { t } = useI18n();
    return {
      t
    };
  },
  methods: {
    getPercentage(answer: string): number {
      if (!this.$props.survey.statistic) {
        return parseFloat("0");
      }

      const answers = this.$props.survey.statistic.answers ?? [];
      for (let i = 0; i < answers.length; i++) {
        if (answers[i].answer === answer) {
          return Math.round(parseFloat(answers[i].percentage));
        }
      }

      return parseFloat("0");
    },
    getPercentageString(answer: string) {
      return this.getPercentage(answer) + "%";
    }
  }
});
