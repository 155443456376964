<template>
  <ScrollPanel class="survey-statistics" :style="{ height: surveyHeight }">
    <div v-if="surveyType === SurveyType.MULTIPLE_CHOICE">
      <SurveyAnswer
        v-for="(option, key) in surveyOptions"
        :key="key"
        :answer="option"
        :percentage="getPercentage(option)"
      ></SurveyAnswer>
    </div>
    <div v-if="surveyType === SurveyType.FREE_TEXT">
      <div class="p-grid">
        <div v-if="surveyStats.total > 1" class="p-col-8 answers-stat">
          {{ t("message.surveyStatisticsTotalTeil1") }} {{ surveyStats.total }}
          {{ t("message.surveyStatisticsTotalTeil2") }}
        </div>
        <div
          v-if="surveyStats.total === 0"
          class="p-col-8 p-text-center no-answers"
        >
          {{ t("message.noSurveyStatisticsAnswers") }}
        </div>
      </div>

      <div
        v-for="(answer, key) in surveyStats.answers"
        :key="key"
        class="p-grid"
      >
        <div class="p-col-8">
          {{ answer.answer }}
        </div>
        <div class="p-col-4 p-text-right">{{ answer.percentage }}%</div>
      </div>
    </div>
  </ScrollPanel>
</template>

<script lang="ts">
import { SurveyStatistic, SurveyType } from "@/store/types";
import { computed, defineComponent, PropType } from "vue";
import ScrollPanel from "primevue/scrollpanel";
import SurveyAnswer from "@/components/SurveyAnswer.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "SurveyStatistics",
  components: {
    ScrollPanel,
    SurveyAnswer
  },
  props: {
    surveyType: {
      type: String as PropType<SurveyType>,
      required: true
    },
    surveyStats: {
      type: Object as PropType<SurveyStatistic>,
      required: true
    },
    surveyOptions: {
      type: Array as PropType<string[]>,
      required: true
    }
  },
  setup: props => {
    const { t } = useI18n();
    const surveyHeight = computed(() => {
      if (props.surveyType === SurveyType.MULTIPLE_CHOICE) {
        return null;
      }

      const gap = 10;
      const n = props.surveyStats.answers.length + 1;
      return `${gap + n * 35}px`;
    });

    const getPercentage = (answer: string) => {
      const filtered = props.surveyStats.answers
        .filter(item => {
          return item.answer === answer;
        })
        .shift();

      return Math.round(parseFloat(filtered?.percentage ?? "0"));
    };

    return {
      t: t,
      SurveyType,
      surveyHeight,
      getPercentage
    };
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../theme/variables.scss";
.survey-statistics {
  max-height: 180px;

  .p-grid {
    margin: 0;
    margin-right: 5px;
    border-bottom: 2px solid $color-background-chat-light;
    align-items: flex-start;

    @media (max-width: 375px) {
      margin-right: 15px;
    }
  }
  .p-col-8 {
    word-break: break-word;
  }
  .answers-stat {
    color: $color-gray-dark;
    width: 100%;
  }
  .no-answers {
    width: 100%;
    color: $color-gray-dark;
    margin-top: 80px;
  }
  .p-text-right {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: $color-gray-dark;
    font-weight: 600;
    font-size: $font-size-small;
  }
}
</style>
