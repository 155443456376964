
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { ChatSurvey, SurveyType } from "@/store/types";
import SurveyStatistics from "@/components/SurveyStatistics.vue";
import SurveyAnswerForm from "@/components/SurveyAnswerForm.vue";

export default defineComponent({
  name: "ActiveSurvey",
  components: {
    SurveyStatistics,
    SurveyAnswerForm
  },
  props: {
    survey: {
      type: Object as PropType<ChatSurvey>,
      required: true
    },
    onAnswered: {
      type: Function as PropType<(survey: ChatSurvey, answer: string) => void>,
      required: true
    },
    onCancel: {
      type: Function as PropType<() => void>,
      required: false,
      default: undefined
    }
  },
  setup: () => {
    const { t } = useI18n();
    return {
      t,
      SurveyType
    };
  }
});
