
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import SurveyStatistics from "@/components/SurveyStatistics.vue";
import ScrollPanel from "primevue/scrollpanel";
import Card from "primevue/card";
import { ActionTypes } from "@/store/actions";
import { ChatSurvey, SurveyState } from "@/store/types";

export default defineComponent({
  name: "RemoteSurveysPage",
  components: {
    SurveyStatistics,
    ScrollPanel,
    Card
  },
  setup: () => {
    const store = useStore();
    const { t } = useI18n();

    const surveysRemoteView = computed(() => store.state.surveysRemoteView);
    const activeSurvey = ref<ChatSurvey>();

    onMounted(async () => {
      await store.dispatch(ActionTypes.FETCH_SURVEYS_REMOTE_VIEW);
    });

    const surveyClasses = (survey: ChatSurvey) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const classes: any = {
        active: true,
        deleted: survey.state === SurveyState.DELETED
      };

      const stateClass = `state-${survey.state}`;
      classes[stateClass] = true;

      return classes;
    };

    const changeActiveSurvey = (survey: ChatSurvey) => {
      activeSurvey.value = survey;
    };

    watch(
      () => surveysRemoteView,
      () => {
        surveysRemoteView.value.forEach((s: ChatSurvey) => {
          if (activeSurvey?.value?.uuid === s.uuid) {
            changeActiveSurvey(s);
          }
        });
      },
      { deep: true, flush: "post" }
    );

    return {
      t: t,
      activeSurvey: activeSurvey,

      fetchedSurveys: surveysRemoteView,
      surveyClasses: surveyClasses,
      changeActiveSurvey: changeActiveSurvey
    };
  }
});
