
import { defineComponent, PropType } from "vue";
import Textarea from "primevue/textarea";
import Button from "primevue/button";
import { useI18n } from "vue-i18n";

const INPUT_WARNING_THRESHOLD_NONE = 50;
const CHAT_TEXT_MAX_LENGTH = 500;

export default defineComponent({
  name: "ChatSubmitForm",
  components: {
    Textarea,
    Button
  },
  props: {
    currentMessage: {
      type: String,
      required: true
    },
    isEnabled: {
      type: Boolean,
      required: true
    },
    isChatActive: {
      type: Boolean,
      required: true
    },
    updateCurrentMessage: {
      type: Function as PropType<(message: string) => void>,
      required: true
    },
    submitMessage: {
      type: Function as PropType<(message: string) => void>,
      required: true
    }
  },
  setup: props => {
    const { t } = useI18n();
    const updateMessage = (event: InputEvent) => {
      const target = event.target as HTMLInputElement;
      const message = target.value;
      props.updateCurrentMessage(message);
    };

    const isInputEnabled = () => {
      return props.isChatActive && props.isEnabled;
    };

    const canSubmit = () => {
      return (
        isInputEnabled() && props.currentMessage.length <= CHAT_TEXT_MAX_LENGTH
      );
    };

    const submit = (message: string) => {
      if (!canSubmit()) {
        return;
      }
      props.submitMessage(message);
    };
    const getClasses = () => {
      return {
        none: props.currentMessage.length <= INPUT_WARNING_THRESHOLD_NONE,
        inactive: !props.isChatActive,
        banned: !props.isEnabled,
        warning: props.currentMessage.length > CHAT_TEXT_MAX_LENGTH - 10,
        error: props.currentMessage.length >= CHAT_TEXT_MAX_LENGTH
      };
    };
    return {
      submit,
      updateMessage,
      canSubmit,
      getClasses,
      t,
      textMaxLength: CHAT_TEXT_MAX_LENGTH,
      isInputEnabled: isInputEnabled,
      getPlaceHolderText: () => {
        if (!props.isChatActive) {
          return t("message.chatIsCurrentlyInactive");
        }

        if (!props.isEnabled) {
          return t("message.chatInputBanned");
        }

        return t("message.chatWriteMessagePrompt");
      },
      getValue: () => {
        if (!props.isEnabled || !props.isChatActive) {
          return "";
        }

        return props.currentMessage;
      }
    };
  }
});
