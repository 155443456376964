<template>
  <div class="chat-message" :class="getClasses()">
    <div class="container-user-time">
      <UserAvatar :user="message.user" />
      <div class="time">
        <span class="approval-waiting">
          <i
            v-if="message.state === ChatMessageState.WAITING"
            class="time pi pi-clock"
          />
        </span>

        {{ t("message.time") }} {{ formatTime(message.timeStamp) }}
      </div>
    </div>

    <div class="container-msg-switch-menu">
      <div class="message" :class="message.state">
        {{ getMessageText() }}
      </div>
      <div class="switch-context-menu">
        <div class="switch" :class="{ 'p-hidden': !isApproval }">
          <InputSwitch v-model="isApproved" @click="approve" />
        </div>

        <ChatContextMenu :message="message" :on-action="onAction" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  ChatMessage,
  ChatMessageContextAction,
  ChatMessageState,
  UserRole
} from "@/store/types";
import { defineComponent, PropType, ref, watch } from "vue";
import UserAvatar from "./UserAvatar.vue";
import { useI18n } from "vue-i18n";
import InputSwitch from "primevue/inputswitch";
import { formatDate } from "@/utils/date";
import ChatContextMenu from "@/components/ChatContextMenu.vue";

export default defineComponent({
  name: "ChatMessage",
  components: {
    ChatContextMenu: ChatContextMenu,
    InputSwitch: InputSwitch,
    UserAvatar
  },
  props: {
    isContextMenu: {
      type: Boolean,
      default: false
    },
    isApproval: {
      type: Boolean,
      default: false
    },
    message: {
      type: Object as PropType<ChatMessage>,
      required: true
    },
    onMarkAsTopic: {
      type: Function as PropType<(message: ChatMessage) => void>,
      required: true
    },
    onBanMessage: {
      type: Function as PropType<(message: ChatMessage) => void>,
      required: true
    },
    onDeleteMessage: {
      type: Function as PropType<(message: ChatMessage) => void>,
      required: true
    },
    onBanUser: {
      type: Function as PropType<(message: ChatMessage) => void>,
      required: true
    },
    onApprove: {
      type: Function as PropType<(message: ChatMessage) => void>,
      required: true
    },
    onRevertApproval: {
      type: Function as PropType<(message: ChatMessage) => void>,
      required: true
    }
  },
  setup(props) {
    const { t } = useI18n();
    const isApproved = ref(props.message.state === ChatMessageState.ENABLED);

    watch(
      () => props.message,
      () => {
        isApproved.value = props.message.state === ChatMessageState.ENABLED;
      }
    );

    return {
      t: t,
      ChatMessageState: ChatMessageState,
      isApproved: isApproved,

      onAction: (action: ChatMessageContextAction, message: ChatMessage) => {
        switch (action) {
          case ChatMessageContextAction.APPROVE_MESSAGE:
            props.onApprove(message);
            return;
          case ChatMessageContextAction.REVERT_APPROVE_MESSAGE:
            props.onRevertApproval(message);
            return;
          case ChatMessageContextAction.MARK_TOPIC:
            props.onMarkAsTopic(message);
            return;
          case ChatMessageContextAction.BLOCK_MESSAGE:
            props.onBanMessage(message);
            return;
          case ChatMessageContextAction.UNBLOCK_MESSAGE:
            props.onApprove(message);
            return;
          case ChatMessageContextAction.BAN_USER:
            props.onBanUser(message);
            return;
          case ChatMessageContextAction.DELETE_MESSAGE:
            props.onDeleteMessage(message);
            return;
        }
      },
      formatTime(timeStamp: string) {
        return formatDate(new Date(parseInt(timeStamp)), "hh:mm");
      },
      getClasses() {
        return {
          blocked: props.message.state !== ChatMessageState.ENABLED,
          waiting: props.message.state === ChatMessageState.WAITING,
          "is-staff":
            props.message.user?.roles?.includes(UserRole.MODERATOR) ||
            props.message.user?.roles?.includes(UserRole.SPEAKER)
        };
      },

      getMessageText() {
        const message = props.message;

        if (props.isApproval) {
          return message.messageUncensored;
        }

        const visible = [ChatMessageState.ENABLED, ChatMessageState.WAITING];
        if (visible.includes(message.state as ChatMessageState)) {
          return message.message;
        }

        return t("message.messageWasBlocked");
      },
      approve() {
        isApproved.value = !isApproved.value;
        if (isApproved.value) {
          props.onApprove(props.message);
        } else {
          props.onRevertApproval(props.message);
        }
      }
    };
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../theme/variables.scss";
@import "../theme/mixins.scss";
.chat-message {
  padding: 6px 10px 10px 5px;
  margin-right: 20px;
  border-radius: $border-radius-large;
  margin: 7px 5px 8px 0;
  background-color: $color-chat-tile;

  &.blocked {
    color: $color-text-btn-gray-normal;
    background-color: #fff;
  }

  &.waiting {
    color: $color-text-btn-gray-normal;
    background-color: #fff;
  }

  &.is-staff {
    font-weight: 600;
  }
  .context-menu-icon {
    font-size: $font-size-small-medium;
  }

  .container-user-time {
    display: flex;
    flex-flow: row;
    align-items: baseline;
    @media (min-width: 375px) {
    }
  }
  .time {
    text-align: left;
    font-size: $font-size-small;
    letter-spacing: 0.4px;
    color: $color-btn-gray-normal;
  }
  .message {
    color: $color-chat-message;
    letter-spacing: 0.25px;
    font-size: $font-size-base;
    word-break: break-word;
    hyphens: auto;
    margin-left: 7px;

    &.blocked {
      font-style: italic;
    }

    &.waiting {
      opacity: 0.5;
    }
  }
}
.container-msg-switch-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .switch-context-menu {
    display: flex;
    .switch {
      margin-right: 15px;
    }
  }
}
</style>
