
import { SurveyStatistic, SurveyType } from "@/store/types";
import { computed, defineComponent, PropType } from "vue";
import ScrollPanel from "primevue/scrollpanel";
import SurveyAnswer from "@/components/SurveyAnswer.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "SurveyStatistics",
  components: {
    ScrollPanel,
    SurveyAnswer
  },
  props: {
    surveyType: {
      type: String as PropType<SurveyType>,
      required: true
    },
    surveyStats: {
      type: Object as PropType<SurveyStatistic>,
      required: true
    },
    surveyOptions: {
      type: Array as PropType<string[]>,
      required: true
    }
  },
  setup: props => {
    const { t } = useI18n();
    const surveyHeight = computed(() => {
      if (props.surveyType === SurveyType.MULTIPLE_CHOICE) {
        return null;
      }

      const gap = 10;
      const n = props.surveyStats.answers.length + 1;
      return `${gap + n * 35}px`;
    });

    const getPercentage = (answer: string) => {
      const filtered = props.surveyStats.answers
        .filter(item => {
          return item.answer === answer;
        })
        .shift();

      return Math.round(parseFloat(filtered?.percentage ?? "0"));
    };

    return {
      t: t,
      SurveyType,
      surveyHeight,
      getPercentage
    };
  }
});
