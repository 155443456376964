
import { defineComponent } from "vue";
import ProgressBar from "primevue/progressbar";

export default defineComponent({
  name: "SurveyAnswer",
  components: {
    ProgressBar
  },
  props: {
    answer: {
      type: String,
      default: () => ""
    },
    percentage: {
      type: Number,
      default: () => 0
    }
  }
});
