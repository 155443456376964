<template>
  <div class="p-grid server-message">
    <div
      class="p-col p-text-center p-text-capitalize"
      style="color: darkred; font-size: small"
    >
      Error: {{ message.message }}
    </div>
  </div>
</template>

<script lang="ts">
import { ErrorMessage } from "@/store/types";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "ErrorMessage",
  props: {
    message: {
      type: Object as PropType<ErrorMessage>,
      required: true
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
