<template>
  <Card class="p-mb-2 topic-card">
    <template #title>
      <div class="container-user-avatar">
        <i class="topic-icon pi pi-tags"></i>
        <div
          class="user-avatar-bubble"
          :style="{ background: getBubbleColored() }"
        >
          <u v-if="isModerator">
            <i class="pi pi-user"></i>
          </u>
          <span v-if="!isModerator">{{ topic.message?.user?.initials }}</span>
        </div>

        <div
          v-if="topic.message.user?.name"
          class="username"
          :class="getClasses()"
        >
          <span class="inner">{{ topic.message.user.name }}</span>
          <div class="time">
            {{ t("message.time") }} {{ formatTime(topic.message.timeStamp) }}
          </div>
        </div>
      </div>
    </template>

    <template #content>
      <div class="topic-message">{{ topic.message.message }}</div>
    </template>

    <template #footer>
      <div class="p-grid">
        <div class="p-col-12 actions">
          <span class="p-mr-2 actions-left">
            <Button
              :class="{
                'p-disabled': topic.state !== ChatTopicState.WAITING
              }"
              class="p-button-sm p-button-info p-mr-2"
              :label="t('message.start')"
              @click="onTopicStart(topic)"
            ></Button>

            <Button
              :class="{
                'p-disabled': topic.state !== ChatTopicState.OPENED
              }"
              class="p-button-sm p-button-info"
              :label="t('message.stop')"
              @click="onTopicStop(topic)"
            ></Button>
          </span>

          <span class="actions-right">
            <Button
              :class="{
                'p-disabled': topic.state === ChatTopicState.OPENED
              }"
              class="p-button-sm p-button-icon-only p-button-secondary"
              icon="pi pi-trash"
              @click="onTopicDelete(topic)"
            ></Button>
          </span>
        </div>
      </div>
    </template>
  </Card>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { ChatTopic, ChatTopicState, UserRole } from "@/store/types";
import Button from "primevue/button";
import Card from "primevue/card";
import { useI18n } from "vue-i18n";
import { formatDate } from "@/utils/date";

export default defineComponent({
  name: "ChatTopicItem",
  components: {
    Button,
    Card
  },
  props: {
    topic: {
      type: Object as PropType<ChatTopic>,
      required: true
    },
    onTopicStart: {
      type: Function as PropType<(topic: ChatTopic) => void>,
      required: true
    },
    onTopicStop: {
      type: Function as PropType<(topic: ChatTopic) => void>,
      required: true
    },
    onTopicDelete: {
      type: Function as PropType<(topic: ChatTopic) => void>,
      required: true
    }
  },
  setup: props => {
    const { t } = useI18n();
    const isModerator = props.topic.message?.user?.roles?.includes(
      UserRole.MODERATOR
    );

    return {
      t,
      ChatTopicState: ChatTopicState,
      isModerator,
      formatTime(timeStamp: string) {
        return formatDate(new Date(parseInt(timeStamp)), "hh:mm");
      },
      getClasses() {
        return {
          isModerator
        };
      },
      getBubbleColored() {
        const color = props.topic.message?.user;

        if (!color) {
          return "";
        }

        return "#" + color.colorHexNumber;
      }
    };
  },
  methods: {}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../theme/variables.scss";

.topic-card {
  background-color: $color-chat-tile;
  padding: 0.2rem;
  margin-right: 5px;
  .topic-message {
    margin: 5px 0 0 5px;
    font-weight: 500;
    word-break: break-word;
  }
}
.container-user-avatar {
  hyphens: auto;
  font-size: $font-size-small-medium;
  letter-spacing: 0.5px;
  /* margin: 5px 0 0 10px; */
  display: flex;
  padding: 5px;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .user-avatar-bubble {
    color: $color-white;
    display: inline-block;
    text-align: center;
    line-height: 1.8rem;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;
    margin-right: 5px;
  }
}

.username {
  display: flex;
  line-height: 1.5;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 400;

  .inner {
    text-overflow: ellipsis;
    overflow: hidden;
    @media (max-width: 330px) {
      max-width: 100px;
    }
  }
  .time {
    margin-left: 10px;
    text-align: left;
    font-size: $font-size-small;
    letter-spacing: 0.4px;
    color: $color-btn-gray-normal;
    line-height: 2.3;
    text-overflow: ellipsis;

    overflow: hidden;
  }

  &.isModerator {
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
.topic-icon {
  color: $color-white;
  background: $color-btn-blue-normal;
  border-radius: $border-radius;
  padding: 5px;
  margin-right: 5px;
  font-size: 20px;
}

.actions {
  display: flex;
  padding: 5px;
  .actions-right {
    margin-left: auto;
    @media (min-width: 320px) and (max-width: 370px) {
      .p-button-sm {
        ::v-deep(.p-button-label) {
          margin-left: 0;
        }
      }
    }
  }
}
.actions-left {
  @media (min-width: 320px) and (max-width: 370px) {
    .p-button-sm {
      ::v-deep(.p-button-label) {
        margin-left: 0;
      }
    }
    .p-button-sm {
      ::v-deep(.p-button-label) {
        margin-left: 0;
      }
    }
  }
}
</style>
