<template>
  <div class="chat-submit-form p-inputgroup" :class="getClasses()">
    <Textarea
      :disabled="!isInputEnabled()"
      :value="getValue()"
      :auto-resize="true"
      rows="1"
      :placeholder="getPlaceHolderText()"
      :maxlength="textMaxLength"
      @input="updateMessage"
      @keydown.enter.exact.prevent
      @keyup.enter.exact="submit()"
    />
    <Button
      icon="pi pi-send"
      class="p-button-text btn-submit"
      :disabled="!canSubmit()"
      @click="submit()"
    />
    <div class="charcount">{{ currentMessage.length }}/{{ textMaxLength }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import Textarea from "primevue/textarea";
import Button from "primevue/button";
import { useI18n } from "vue-i18n";

const INPUT_WARNING_THRESHOLD_NONE = 50;
const CHAT_TEXT_MAX_LENGTH = 500;

export default defineComponent({
  name: "ChatSubmitForm",
  components: {
    Textarea,
    Button
  },
  props: {
    currentMessage: {
      type: String,
      required: true
    },
    isEnabled: {
      type: Boolean,
      required: true
    },
    isChatActive: {
      type: Boolean,
      required: true
    },
    updateCurrentMessage: {
      type: Function as PropType<(message: string) => void>,
      required: true
    },
    submitMessage: {
      type: Function as PropType<(message: string) => void>,
      required: true
    }
  },
  setup: props => {
    const { t } = useI18n();
    const updateMessage = (event: InputEvent) => {
      const target = event.target as HTMLInputElement;
      const message = target.value;
      props.updateCurrentMessage(message);
    };

    const isInputEnabled = () => {
      return props.isChatActive && props.isEnabled;
    };

    const canSubmit = () => {
      return (
        isInputEnabled() && props.currentMessage.length <= CHAT_TEXT_MAX_LENGTH
      );
    };

    const submit = (message: string) => {
      if (!canSubmit()) {
        return;
      }
      props.submitMessage(message);
    };
    const getClasses = () => {
      return {
        none: props.currentMessage.length <= INPUT_WARNING_THRESHOLD_NONE,
        inactive: !props.isChatActive,
        banned: !props.isEnabled,
        warning: props.currentMessage.length > CHAT_TEXT_MAX_LENGTH - 10,
        error: props.currentMessage.length >= CHAT_TEXT_MAX_LENGTH
      };
    };
    return {
      submit,
      updateMessage,
      canSubmit,
      getClasses,
      t,
      textMaxLength: CHAT_TEXT_MAX_LENGTH,
      isInputEnabled: isInputEnabled,
      getPlaceHolderText: () => {
        if (!props.isChatActive) {
          return t("message.chatIsCurrentlyInactive");
        }

        if (!props.isEnabled) {
          return t("message.chatInputBanned");
        }

        return t("message.chatWriteMessagePrompt");
      },
      getValue: () => {
        if (!props.isEnabled || !props.isChatActive) {
          return "";
        }

        return props.currentMessage;
      }
    };
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../theme/variables.scss";
@import "../theme/_mixins.scss";

.chat-submit-form {
  padding: 7px;
  background-color: $color-white;
  border-radius: $border-radius-large;
  border: 1px solid $border-color-light-gray;
  align-items: center;
  position: relative;

  .p-button-text {
    @include generalBtnStyle;
    background-color: transparent !important;
    color: $color-btn-blue-normal;
    position: absolute;
    right: 0;
    bottom: 5px;

    &:hover {
      color: $color-btn-blue-hover;
    }
    &:disabled {
      color: $color-text-btn-gray-disabled;
    }
  }
  .p-inputtextarea {
    border: none;
    background-color: transparent;
    max-height: 300px;
    padding-right: 65px;
    word-break: break-word;
    hyphens: auto;
  }
  .charcount {
    position: absolute;
    right: 4px;
    top: 1px;
    opacity: 0.9;
    font-size: 12px;
    color: $border-color-light-gray;
  }
  &.warning {
    border: 1px solid $color-btn-red-disabled;
    .charcount {
      color: $color-btn-red-disabled;
    }
  }
  &.error {
    border: 1px solid $color-btn-red-hover;
    .charcount {
      color: $color-btn-red-hover;
    }
  }
  &.none {
    .charcount {
      display: none;
    }
  }
  &.banned {
    ::placeholder {
      color: $color-btn-red-normal;
    }

    border: 1px solid $color-btn-red-normal;
  }
  &.inactive {
    ::placeholder {
      color: $color-text-btn-gray-disabled;
    }

    border: 1px solid $color-text-btn-gray-disabled;
  }
}
</style>
