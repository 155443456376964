<template>
  <div class="p-grid">
    <div class="p-col-6">
      {{ answer }}
    </div>
    <div class="p-col-6 p-text-right">
      <div class="p-grid">
        <div class="p-col-9">
          <ProgressBar
            class="answer-progress"
            :value="percentage"
            :show-value="false"
          ></ProgressBar>
        </div>
        <div class="p-col-2 p-text-left">
          {{ percentage + "%" }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ProgressBar from "primevue/progressbar";

export default defineComponent({
  name: "SurveyAnswer",
  components: {
    ProgressBar
  },
  props: {
    answer: {
      type: String,
      default: () => ""
    },
    percentage: {
      type: Number,
      default: () => 0
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../theme/variables.scss";
.answer-progress {
  margin-top: 6px;
  height: 0.5rem;
}
.p-col-6 {
  font-size: $font-size-small-medium;
}
</style>
