
import { ServerMessage } from "@/store/types";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "ServerMessage",
  props: {
    message: {
      type: Object as PropType<ServerMessage>,
      required: true
    }
  }
});
