
import { defineComponent, onMounted, PropType, ref } from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";

const NAME_INPUT_WARNING_THRESHOLD_NONE = 8;
const NAME_MAX_LENGTH = 16;

export default defineComponent({
  name: "UserNameForm",
  components: {
    InputText,
    Button
  },
  props: {
    userName: {
      type: String,
      default: () => ""
    },
    profileSaving: {
      type: Boolean,
      default: () => false
    },
    profileError: {
      type: Boolean,
      default: () => false
    },
    submitUserName: {
      type: Function as PropType<(message: string) => void>,
      required: true
    },
    cancelEdit: {
      type: Function as PropType<() => void>,
      required: true
    }
  },
  setup: props => {
    const userNameState = ref<string>(props.userName);

    const elem = ref<HTMLDivElement>();

    const canSubmit = () => userNameState.value.length <= NAME_MAX_LENGTH;
    const submit = () => {
      if (!canSubmit()) {
        return;
      }
      props.submitUserName(userNameState.value);
    };

    const cancel = () => {
      userNameState.value = props.userName;
      props.cancelEdit();
    };

    onMounted(() => {
      const input = elem.value?.querySelector("input");
      input?.focus();
      input?.select();
    });

    const getClasses = () => {
      return {
        none: userNameState.value.length <= NAME_INPUT_WARNING_THRESHOLD_NONE,
        warning: userNameState.value.length > NAME_MAX_LENGTH - 10,
        error: userNameState.value.length >= NAME_MAX_LENGTH
      };
    };

    return {
      userNameState,
      elem,
      canSubmit,
      getClasses,
      submit,
      cancel,
      textMaxLength: NAME_MAX_LENGTH
    };
  },
  data() {
    return {
      name: this.$props.userName
    };
  }
});
