<template>
  <div class="p-d-flex p-jc-between p-ai-start">
    <div class="username">
      <UserNameForm
        v-if="showForm"
        class="username-form"
        :user-name="userName"
        :profile-saving="profileSaving"
        :profile-error="profileError"
        :submit-user-name="submit"
        :cancel-edit="toggleShowForm"
      />
      <div class="inner">
        {{ userName }}
      </div>
      <Button
        style="padding: 0"
        type="Button"
        icon="pi pi-pencil"
        class="p-button-text"
        :disabled="profileSaving"
        @click="toggleShowForm()"
      />
    </div>
  </div>
  <div class="p-mt-2">
    <slot name="footer"></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from "vue";
import UserNameForm from "@/components/UserNameForm.vue";
import Button from "primevue/button";

export default defineComponent({
  name: "UserName",
  components: {
    Button,
    UserNameForm
  },
  props: {
    userName: {
      type: String,
      default: () => ""
    },
    submitUserName: {
      type: Function as PropType<(message: string) => void>,
      required: true
    },
    profileSaving: {
      type: Boolean,
      default: () => false
    },
    profileError: {
      type: Boolean,
      default: () => false
    }
  },
  setup: props => {
    const showForm = ref(false);
    const toggleShowForm = () => {
      showForm.value = !showForm.value;
    };
    const submit = async (newName: string) => {
      await props.submitUserName(newName);
      if (!props.profileError) {
        showForm.value = false;
      }
    };
    return {
      showForm,
      toggleShowForm,
      submit
    };
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../theme/variables.scss";

.username {
  padding-bottom: 10px;
  letter-spacing: 0.18px;
  color: $color-black;
  font-size: $font-size-large;
  font-weight: 500;
  line-height: 1;
  z-index: 1500;
  display: flex;

  @media (max-width: 375px) {
    max-width: 200px;
  }

  .inner {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .username-form {
    position: absolute;
    top: 15px;
    z-index: 100;
    width: 95%;
  }

  .p-button.p-button-icon-only {
    width: 1.5rem;
    @media (min-width: 375px) {
      width: 2.357rem;
    }
  }
}
</style>
