<template>
  <div class="user-page">
    <h3>{{ t("message.usersTabTitle") }}</h3>
    <ScrollPanel class="user-page page scroll-user-container">
      <UserItem
        v-for="(user, key) in users"
        :key="key"
        :user="user"
        :on-user-block="blockUser"
        :on-user-unblock="unblockUser"
      />
    </ScrollPanel>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "@/store";
import { useI18n } from "vue-i18n";
import ContextMenu from "primevue/contextmenu";
import UserItem from "@/components/UserItem.vue";
import { ActionTypes } from "@/store/actions";
import { ChatUser } from "@/store/types";
import ScrollPanel from "primevue/scrollpanel";

export default defineComponent({
  name: "UsersPage",
  components: {
    UserItem: UserItem,
    ScrollPanel
  },
  setup() {
    const store = useStore();
    const activeUsers = computed(() => store.getters.getActiveUsers);
    const contextModMenu = ref<ContextMenu>();
    onMounted(async () => {
      await store.dispatch(ActionTypes.FETCH_USERS);
    });

    const { t } = useI18n();
    return {
      t: t,
      users: activeUsers,
      contextModMenu: contextModMenu,

      openContextMenu(event: Event) {
        contextModMenu.value?.toggle(event);
      },
      blockUser: (user: ChatUser) => {
        const uuid = user?.uuid;
        if (!uuid) {
          return;
        }

        store.dispatch(ActionTypes.BLOCK_USER, uuid);
      },
      unblockUser: (user: ChatUser) => {
        const uuid = user?.uuid;
        if (!uuid) {
          return;
        }

        store.dispatch(ActionTypes.UNBLOCK_USER, uuid);
      }
    };
  }
});
</script>

<style scoped lang="scss">
@import "../../theme/variables.scss";
.user-page {
  height: calc(100vh - 95px);
  width: 100%;

  .scroll-user-container {
    height: calc(100vh - 150px);
    width: 100%;
  }
}
</style>
