<template>
  <div class="page-content-survey-remote">
    <h3>{{ t("message.surveyRemotePageTitle") }}</h3>

    <div class="p-grid">
      <div class="p-col-4">
        <div ref="scrollPanel" class="scroll-survey-container">
          <ScrollPanel>
            <div v-if="fetchedSurveys">
              <div v-for="(survey, key) in fetchedSurveys || []" :key="key">
                <div
                  class="survey-item"
                  :class="surveyClasses(survey)"
                  @click="changeActiveSurvey(survey)"
                >
                  <div class="blur-deleted"></div>

                  <Card class="survey-card p-shadow-1 p-mb-2">
                    <template #title>
                      <i class="survey-icon pi pi-chart-bar p-mr-1"></i>
                      <span class="survey-question">
                        {{ survey.question }}
                      </span>
                    </template>

                    <template #content>
                      <div>
                        <div class="user-badge p-badge">
                          {{ t("message.surveyStateLabel." + survey.state) }}
                        </div>
                      </div>
                    </template>
                  </Card>
                </div>
              </div>
            </div>
          </ScrollPanel>
        </div>
      </div>

      <!-- ACTIVE SURVEY -->
      <div class="p-col-8">
        <div class="active-survey-wrapper">
          <div class="row">
            <Card v-if="activeSurvey" class="active-survey">
              <template #title>
                <i class="survey-icon pi pi-chart-bar p-mr-2"></i>
                <span class="survey-question">
                  {{ activeSurvey.question }}
                </span>
              </template>

              <template #content>
                <div class="p-mt-4">
                  <SurveyStatistics
                    v-if="activeSurvey.statistic"
                    :survey-type="activeSurvey.type"
                    :survey-options="activeSurvey.options"
                    :survey-stats="activeSurvey.statistic"
                  />
                </div>
              </template>
            </Card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import SurveyStatistics from "@/components/SurveyStatistics.vue";
import ScrollPanel from "primevue/scrollpanel";
import Card from "primevue/card";
import { ActionTypes } from "@/store/actions";
import { ChatSurvey, SurveyState } from "@/store/types";

export default defineComponent({
  name: "RemoteSurveysPage",
  components: {
    SurveyStatistics,
    ScrollPanel,
    Card
  },
  setup: () => {
    const store = useStore();
    const { t } = useI18n();

    const surveysRemoteView = computed(() => store.state.surveysRemoteView);
    const activeSurvey = ref<ChatSurvey>();

    onMounted(async () => {
      await store.dispatch(ActionTypes.FETCH_SURVEYS_REMOTE_VIEW);
    });

    const surveyClasses = (survey: ChatSurvey) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const classes: any = {
        active: true,
        deleted: survey.state === SurveyState.DELETED
      };

      const stateClass = `state-${survey.state}`;
      classes[stateClass] = true;

      return classes;
    };

    const changeActiveSurvey = (survey: ChatSurvey) => {
      activeSurvey.value = survey;
    };

    watch(
      () => surveysRemoteView,
      () => {
        surveysRemoteView.value.forEach((s: ChatSurvey) => {
          if (activeSurvey?.value?.uuid === s.uuid) {
            changeActiveSurvey(s);
          }
        });
      },
      { deep: true, flush: "post" }
    );

    return {
      t: t,
      activeSurvey: activeSurvey,

      fetchedSurveys: surveysRemoteView,
      surveyClasses: surveyClasses,
      changeActiveSurvey: changeActiveSurvey
    };
  }
});
</script>
<style lang="scss">
@import "../../theme/variables.scss";

.scroll-survey-container {
  .p-scrollpanel {
    height: calc(100vh - 200px);
    width: 100%;

    @media (min-width: 320px) and (max-width: 370px) {
      height: calc(100vh - 155px);
    }
  }
}

.page-content-survey-remote {
  height: calc(100vh - 110px);

  .active-survey-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;

    background: #fff;

    .active-survey {
      border: 1px solid #000000;
      width: 600px;
    }
  }

  .survey-item {
    position: relative;
    cursor: pointer;

    .blur-deleted {
      position: absolute;
      display: none;
      z-index: 9999;

      height: 100%;
      width: 100%;
      top: 0;

      user-select: none;
      user-focus: none;
      background: rgba(250, 250, 250, 0.6);
    }

    &.state-deleted .blur-deleted {
      display: block;
    }
  }
}
</style>
