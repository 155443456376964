<template>
  <div class="new-survey">
    <h3>{{ t("message.surveyTabTitle") }}</h3>
    <Button
      icon="pi pi-plus"
      class="p-button p-mt-2 new-survey-btn"
      @click="onSurveyEdit(survey)"
    ></Button>
  </div>

  <div ref="scrollPanel" class="scroll-survey-container">
    <ScrollPanel>
      <div v-if="surveys">
        <div v-for="(survey, key) in surveys" :key="key">
          <ChatSurveyItem
            :survey="survey"
            :on-survey-start="onSurveyStart"
            :on-survey-cancel="onSurveyCancel"
            :on-survey-edit="onSurveyEdit"
            :on-survey-delete="onSurveyDelete"
          ></ChatSurveyItem>
        </div>
      </div>
    </ScrollPanel>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { ChatSurvey } from "@/store/types";
import ChatSurveyItem from "@/components/ChatSurveyItem.vue";
import ScrollPanel from "primevue/scrollpanel";
import Button from "primevue/button";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "ChatSurveyList",
  components: {
    ChatSurveyItem,
    ScrollPanel,
    Button
  },
  inheritAttrs: false,
  props: {
    surveys: {
      type: Array as PropType<ChatSurvey[]>,
      default: () => []
    },

    onSurveyStart: {
      type: Function as PropType<(survey: ChatSurvey) => {}>,
      required: true
    },
    onSurveyCancel: {
      type: Function as PropType<(survey: ChatSurvey) => {}>,
      required: true
    },
    onSurveyEdit: {
      type: Function as PropType<(survey: ChatSurvey) => {}>,
      required: true
    },
    onSurveyDelete: {
      type: Function as PropType<(survey: ChatSurvey) => {}>,
      required: true
    }
  },
  setup: () => {
    const { t } = useI18n();
    return {
      t
    };
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../theme/variables.scss";

.scroll-survey-container {
  .p-scrollpanel {
    height: calc(100vh - 155px);
    width: 100%;
  }
}

.new-survey {
  display: flex;
  align-items: baseline;
  h3 {
    margin-right: 10px;
  }
  .new-survey-btn {
    background-color: $color-btn-blue-normal;
    color: white;
    padding: 0.3rem;
    height: 2rem;
    z-index: 1500;

    @media (min-width: 320px) and (max-width: 370px) {
      ::v-deep(.p-button-label) {
        display: none;
      }
    }
  }
}
</style>
