<template>
  <div class="p-fluid p-field p-input-filled">
    <InputText
      id="question"
      v-model="question"
      :placeholder="t('message.surveyAskQuestion')"
      class="input-question"
      type="text"
      autofocus
      required
    />
  </div>
  <span>{{ t("message.surveyChoseSurveyMode") }}</span>
  <div
    v-if="!surveyType || showSurveyTypeSelect"
    class="p-fluid p-mt-4 container"
  >
    <Button
      class="new-survey-btn p-button-sm p-component p-button-info p-mt-2"
      :label="t('message.surveySurveyModeMultiple')"
      @click="choseSurveyType(SurveyType.MULTIPLE_CHOICE)"
    />
    <p class="survey-choice">{{ t("message.surveyChoice") }}</p>
    <Button
      class="new-survey-btn p-button-sm p-component p-button-info"
      :label="t('message.surveySurveyModeFreeText')"
      @click="choseSurveyType(SurveyType.FREE_TEXT)"
    />
  </div>
  <div
    v-if="surveyType === SurveyType.MULTIPLE_CHOICE"
    id="multipleChoice"
    class="p-fluid p-mt-3"
  >
    <div
      v-for="option in options"
      :key="option.key"
      class="p-field p-input-filled p-mb-4"
    >
      <InputText
        :id="'option' + option.key"
        type="text"
        :data-key="option.key"
        :value="option.value"
        :placeholder="option.label"
        required
        class="input-answer"
        :maxlength="textMaxLength"
        @input="updateOptions"
      />
      <label :for="'option' + option.key" style="display:none"
        >{{ option.label }}..</label
      >
    </div>
  </div>
  <div
    v-if="surveyType === SurveyType.MULTIPLE_CHOICE"
    class="p-d-flex p-jc-between"
  >
    <Button
      class="p-button-sm p-component p-button-filled p-button-secondary"
      :label="t('message.surveyAddAnswer')"
      @click="addOption"
    />
  </div>
  <div class="p-d-flex p-jc-between p-pt-4 p-field">
    <Button
      :disabled="!isValid()"
      class="p-button-sm p-component p-button-filled p-button-info"
      :label="t('message.save')"
      @click="submit"
    />

    <Button
      class="p-ml-1 p-button-sm p-button-text p-button-danger"
      :label="t('message.cancel')"
      @click="cancel"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType, ref, toRefs, watch } from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { ChatSurvey, SurveyType } from "@/store/types";
import { useI18n } from "vue-i18n";

interface SurveyFormOption {
  key: string;
  label: string;
  value: string;
}

const SURVEY_TEXT_MAX_LENGTH = 150;

export default defineComponent({
  name: "SurveySubmitForm",
  components: {
    InputText,
    Button
  },
  props: {
    survey: {
      type: Object as PropType<ChatSurvey | null>,
      default: null,
      required: false
    },
    onSubmitHandler: {
      type: Function as PropType<(survey: ChatSurvey) => void>,
      required: true
    },
    onCancelHandler: {
      type: Function as PropType<() => void>,
      required: true
    },
    minOptions: {
      type: Number,
      default: () => {
        return 2;
      }
    },
    maxOptions: {
      type: Number,
      default: () => {
        return 4;
      }
    }
  },
  setup: props => {
    const { t } = useI18n();
    const { survey } = toRefs(props);
    const surveyType = ref<SurveyType>();
    const showSurveyTypeSelect = ref<boolean>(true);
    const options = ref<SurveyFormOption[]>([]);
    const question = ref("");

    const setupOptions = () => {
      if (survey.value) {
        question.value = survey.value.question;
        surveyType.value = survey.value.type;
        options.value = survey.value.options.map((option, index) => {
          const key: number = index + 1;
          return {
            key: key.toString(),
            label: `${t("message.surveyOptionTitle")} ${key}`,
            value: option
          };
        });
      } else {
        question.value = "";
        surveyType.value = undefined;
        options.value = [
          {
            key: "1",
            label: t("message.surveyOptionTitle").concat(" 1"),
            value: ""
          },
          {
            key: "2",
            label: t("message.surveyOptionTitle").concat(" 2"),
            value: ""
          }
        ];
      }
    };

    onMounted(setupOptions);
    watch(survey, setupOptions);

    const showTypeSelect = () => {
      showSurveyTypeSelect.value = true;
    };

    const choseSurveyType = (type: SurveyType) => {
      surveyType.value = type;
      showSurveyTypeSelect.value = false;
      if (type === SurveyType.FREE_TEXT) {
        options.value = [];
      }
    };

    const isValid = () => {
      if (!surveyType.value) return false;
      if (
        surveyType.value === SurveyType.MULTIPLE_CHOICE &&
        options.value.length === 0
      ) {
        return false;
      }

      if (surveyType.value === SurveyType.MULTIPLE_CHOICE) {
        return !options.value.some(option => option.value === "");
      }

      if (question.value === "") return false;
      return true;
    };

    const submit = () => {
      if (!surveyType.value) return;
      if (
        surveyType.value === SurveyType.MULTIPLE_CHOICE &&
        options.value.length === 0
      )
        return;

      const surveyPayload: ChatSurvey = {
        type: surveyType.value,
        uuid: survey.value?.uuid,
        question: question.value,
        options: options.value.map(option => option.value)
      };
      props.onSubmitHandler(surveyPayload);
    };
    const cancel = () => {
      survey.value = null;
      setupOptions();
      props.onCancelHandler();
    };
    const addOption = () => {
      if (options.value.length >= props.maxOptions) {
        return;
      }
      options.value.push({
        key: (options.value.length + 1).toString(),
        label: `${t("message.surveyOptionTitle")} ${options.value.length + 1}`,
        value: ""
      });
    };

    const removeOption = () => {
      if (options.value.length <= props.minOptions) {
        return;
      }
      options.value.pop();
    };

    const updateOptions = (event: InputEvent) => {
      const target = event.target as HTMLInputElement;
      const key = target.attributes.getNamedItem("data-key")?.value;
      options.value.forEach((option, index) => {
        if (option.key === key) {
          options.value[index].value = target.value;
        }
      });
    };

    return {
      t,
      options,
      question,
      SurveyType,
      surveyType,
      showTypeSelect,
      showSurveyTypeSelect,
      choseSurveyType,
      isValid,
      submit,
      cancel,
      addOption,
      removeOption,
      updateOptions,
      textMaxLength: SURVEY_TEXT_MAX_LENGTH
    };
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../theme/variables.scss";

#question,
.input-answer {
  height: 50px;
  padding: 10px;
  margin-top: 5px;
  background-color: $color-background-chat-light;
  border-radius: $border-radius-large;
  border-color: transparent;
}

.survey-choice {
  text-align: center;
  margin: 0;
}
.container {
  text-align: center;
}
.new-survey-btn:nth-child(odd) {
  margin: 10px 0 10px;
  width: auto;
}
</style>
