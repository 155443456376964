import { createStore } from "vuex";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import {
  ChatMessage,
  ChatRoom,
  ChatSurvey,
  ChatTopic,
  ChatUser,
  Store,
  FlashMessage,
  ServerMessage
} from "./types";
import createWebSocketPlugin from "@/plugins/socket-io-vuex";
import socket from "@/utils/socket";

export interface RootState {
  language: string;
  flashMessages: FlashMessage[];
  isLoading: boolean;
  isLoggedIn: boolean;
  loginCode?: string;
  loginToken?: string;
  askForLoginCode: boolean;
  profileError: boolean;
  profileSaving: boolean;
  accessToken?: string;
  user?: ChatUser;
  socketConnected: boolean;
  desiredRoomUuid?: string;
  activeRoom?: ChatRoom;
  activeSurvey?: ChatSurvey;
  surveys: ChatSurvey[];
  surveysRemoteView?: ChatSurvey[];
  serverMessages: ServerMessage[];
  messages: Array<ChatMessage>;
  currentMessage?: string;
  activeUsers: ChatUser[];
  topics: Array<ChatTopic>;
  activeTopic?: ChatTopic;
  activeMessageContextMenu: string;
}

export const store = createStore({
  state: {
    language: "de",
    flashMessages: [],
    isLoading: false,
    profileSaving: false,
    isLoggedIn: false,
    profileError: false,
    askForLoginCode: false,
    socketConnected: false,
    desiredRoomUuid: undefined,
    serverMessages: [],
    messages: [],
    activeUsers: [],
    topics: [],
    surveys: [],
    surveysRemoteView: [],
    activeMessageContextMenu: ""
  },
  getters,
  mutations,
  actions,
  plugins: [createWebSocketPlugin(socket)]
});

export function useStore() {
  return store as Store;
}
