
import { defineComponent, PropType, ref } from "vue";
import UserNameForm from "@/components/UserNameForm.vue";
import Button from "primevue/button";

export default defineComponent({
  name: "UserName",
  components: {
    Button,
    UserNameForm
  },
  props: {
    userName: {
      type: String,
      default: () => ""
    },
    submitUserName: {
      type: Function as PropType<(message: string) => void>,
      required: true
    },
    profileSaving: {
      type: Boolean,
      default: () => false
    },
    profileError: {
      type: Boolean,
      default: () => false
    }
  },
  setup: props => {
    const showForm = ref(false);
    const toggleShowForm = () => {
      showForm.value = !showForm.value;
    };
    const submit = async (newName: string) => {
      await props.submitUserName(newName);
      if (!props.profileError) {
        showForm.value = false;
      }
    };
    return {
      showForm,
      toggleShowForm,
      submit
    };
  }
});
