
import { ChatUser, UserRole } from "@/store/types";
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "UserAvatar",
  components: {},
  props: {
    user: {
      type: Object as PropType<ChatUser>,
      required: true
    }
  },
  setup(props) {
    const { t } = useI18n();
    const moderator = props.user.roles?.includes(UserRole.MODERATOR);

    return {
      t: t,
      moderator,

      getBubbleColored() {
        const color = props.user;

        if (!color) {
          return "";
        }

        return "#" + color.colorHexNumber;
      }
    };
  }
});
