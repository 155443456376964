
import { computed, defineComponent, ref, watch } from "vue";
import { useStore } from "@/store";
import ProgressSpinner from "primevue/progressspinner";

import LoginPage from "@/pages/LoginPage/LoginPage.vue";
import ChatPage from "@/pages/ChatPage/ChatPage.vue";
import SurveysPage from "@/pages/SurveysPage/SurveysPage.vue";
import TopicsPage from "@/pages/TopicsPage/TopicsPage.vue";

import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./theme/overrides.scss";
import ModSwitch from "@/components/ModSwitch.vue";
import {
  ChatRoomMode,
  FlashMessage,
  PageType,
  SettingKey,
  SettingsEntry,
  UserRole
} from "@/store/types";
import ChatSubmitForm from "@/components/ChatSubmitForm.vue";
import { ActionTypes } from "@/store/actions";
import { MutationTypes } from "@/store/mutations";
import UsersPage from "@/pages/UsersPage/UsersPage.vue";
import RemoteSurveysPage from "@/pages/RemoteSurveysPage/RemoteSurveysPage.vue";
import SettingsPanel from "@/components/SettingsPanel.vue";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "App",
  components: {
    ProgressSpinner,
    LoginPage,
    ChatPage,
    SurveysPage,
    RemoteSurveysPage,
    TopicsPage,
    UsersPage,
    ChatSubmitForm,
    SettingsPanel,
    Toast,
    ModSwitch
  },
  setup: () => {
    const store = useStore();
    const currentUser = computed(() => store.getters.getUser);
    const isChatActive = computed(() => {
      return !!store.getters.getActiveRoom?.isActive;
    });

    const page = ref<PageType>(PageType.CHAT);

    const { t } = useI18n();
    const toast = useToast();

    const flashMessages = computed(() => store.state.flashMessages);
    watch(flashMessages, () => {
      const messages: FlashMessage[] = flashMessages.value;
      if (!messages || !messages.length) {
        return;
      }

      messages.forEach((m, key) => {
        const message = m.messagePlain ?? t(m.message);
        toast.add({
          severity: m.type,
          summary: m.title ? t(m.title) : "",
          detail: message ?? "",
          life: m.duration ?? 4000
        });
        store.commit(MutationTypes.REMOVE_FLASH_MESSAGE, key);
        if (typeof m.onShown !== "undefined") {
          setTimeout(() => {
            m.onShown && m.onShown();
          }, m.duration);
        }
      });
    });

    const changePage = (p: PageType) => {
      page.value = p;
    };

    const isModSwitch = () => {
      if (!currentUser.value || !currentUser.value.roles) {
        return false;
      }

      const roles = currentUser.value.roles;
      if (roles.includes(UserRole.SPEAKER)) {
        return true;
      }

      if (roles.includes(UserRole.MODERATOR)) {
        return true;
      }

      return false;
    };

    watch(
      () => store.getters.getIsloggedIn,
      () => {
        if (
          store.getters.getIsloggedIn &&
          window.location.href.indexOf("remote") >= 0
        ) {
          const isMod =
            currentUser.value?.roles.includes(UserRole.SPEAKER) ||
            currentUser.value?.roles.includes(UserRole.MODERATOR);

          if (isMod) {
            changePage(PageType.REMOTE_SURVEYS);
          }
        }
      }
    );

    return {
      page: page,
      user: currentUser,
      isChatActive: isChatActive,
      isLoading: computed(() => store.getters.getIsLoading),
      isLoggedIn: computed(() => store.getters.getIsloggedIn),
      isModMode: computed(() => store.getters.getIsModMode),
      currentChatMode: computed(() => store.getters.getCurrentChatMode),
      currentMessage: computed(() => store.state.currentMessage || ""),
      changePage: changePage,
      isModSwitch: isModSwitch,

      logout: () => {
        store.dispatch(ActionTypes.LOGOUT);
      },
      isChatInputAllowed: () => {
        return !currentUser.value?.isBanned;
      },
      updateCurrentMessage: (message: string) => {
        store.commit(MutationTypes.SET_CURRENT_MESSAGE, message);
      },
      settingChanged: (setting: SettingsEntry) => {
        if (setting.key == SettingKey.CHAT_MODE) {
          store.dispatch(
            ActionTypes.UPDATE_CHAT_MODE,
            setting.value as ChatRoomMode
          );
        }
        if (setting.key == SettingKey.CHAT_ACTIVATION_STATE) {
          store.dispatch(
            ActionTypes.UPDATE_CHAT_ACTIVATION_STATE,
            setting.value as boolean
          );
        }
      },
      goToRemoteSurveys: () => {
        const remoteSurveyUrl =
          window.location.href +
          (window.location.href.indexOf("?") >= 0 ? "&remote" : "?remote");

        window.open(remoteSurveyUrl, "_blank");
      },
      clearChatRoom: () => {
        store.dispatch(ActionTypes.DELETE_MESSAGES);
      },
      sendMessage: () => {
        if (!store.state.currentMessage) return;
        store.dispatch(ActionTypes.SEND_MESSAGE, {
          message: store.state.currentMessage
        });
      }
    };
  }
});
