<template>
  <div class="p-grid server-message">
    <div class="p-col p-text-center p-text-secondary">
      {{ message.message }}
    </div>
  </div>
</template>

<script lang="ts">
import { ServerMessage } from "@/store/types";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "ServerMessage",
  props: {
    message: {
      type: Object as PropType<ServerMessage>,
      required: true
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.server-message {
  padding: 10px;
}
</style>
