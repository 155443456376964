<template>
  <div v-if="isActive" class="mvc-mod-switch">
    <Button
      :class="{ active: activePage === 'chat' }"
      class="switch-btn tab-btn p-button-text"
      icon="pi pi-comments"
      :label="t('message.chatTabTitle')"
      @click="changePage('chat')"
    />

    <Button
      :class="{ active: activePage === 'approval' }"
      class="switch-btn tab-btn p-button-text p-mr-3"
      icon="pi pi-eye"
      :label="t('message.approvalTabTitle')"
      @click="changePage('approval')"
    />

    <Button
      :class="{ active: activePage === 'surveys' }"
      class="switch-btn tab-btn p-button-text"
      icon="pi pi-chart-bar"
      :label="t('message.surveyTabTitle')"
      @click="changePage('surveys')"
    />

    <Button
      :class="{ active: activePage === 'topics' }"
      class="switch-btn tab-btn p-button-text"
      icon="pi pi-tags"
      :label="t('message.topicsTabTitle')"
      @click="changePage('topics')"
    />

    <Button
      :class="{ active: activePage === 'users' }"
      class="switch-btn tab-btn p-button-text"
      icon="pi pi-users"
      :label="t('message.usersTabTitle')"
      @click="changePage('users')"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import Button from "primevue/button";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "ModSwitch",
  components: {
    Button
  },
  props: {
    page: {
      type: String,
      default: () => "chat"
    },
    isActive: {
      type: Boolean,
      default: () => true
    }
  },
  emits: ["page-changed"],
  setup: (props, { emit }) => {
    const { t } = useI18n();
    const activePage = ref<string>("chat");

    return {
      t,
      activePage,

      changePage(name: string) {
        activePage.value = name;
        emit("page-changed", activePage.value);
      }
    };
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../theme/variables.scss";
.mvc-mod-switch {
  padding-top: 5px;
  position: absolute;
  bottom: -55px;
  @media (min-width: 320px) and (max-width: 370px) {
    bottom: -50px;
  }
  @media (min-width: 768px) and (max-width: 1000px) {
    bottom: -55px;
  }
}

.tab-btn:hover {
  user-focus: none;
  user-select: none;
}

.tab-btn:focus {
  outline: none;
  box-shadow: none;
}

.switch-btn {
  &.active {
    border: 1px solid;

    @media (min-width: 320px) and (max-width: 512px) {
      font-size: $font-size-small;
      padding: 0.5rem;
    }
  }

  &:not(.active) {
    ::v-deep(.p-button-label) {
      display: none !important;
    }

    @media (min-width: 1024px) {
      ::v-deep(.p-button-label) {
        display: block !important;
      }
    }
  }
  @media (min-width: 320px) and (max-width: 512px) {
    ::v-deep(.p-button-label) {
      display: none !important;
    }
  }
}
</style>
