import { GetterTree } from "vuex";
import { RootState } from ".";
import {
  AnyObject,
  ChatMessage,
  ChatMessageState,
  ChatRoom,
  ChatRoomMode,
  ChatTopic,
  ChatUser,
  Message,
  UserRole
} from "./types";
import { MessageType } from "@/utils/socket";

export type Getters = {
  getIsLoading: (state: RootState) => boolean;
  getIsloggedIn: (state: RootState) => boolean;
  getLoginToken: (state: RootState) => string | undefined;
  getActiveRoom: (state: RootState) => ChatRoom | undefined;
  getCurrentChatMode: (state: RootState) => ChatRoomMode | undefined;
  getAccessToken: (state: RootState) => string | undefined;
  getUser: (state: RootState) => ChatUser | undefined;
  getUserId: (state: RootState) => string | undefined;
  getUserName: (state: RootState) => string | undefined;
  getMessages: (state: RootState) => AnyObject[];
  getTopics: (state: RootState) => ChatTopic[];
  getIsModMode: (state: RootState) => boolean;
  getVisibleMessages: (state: RootState) => ChatMessage[];
  getAllMessages: (state: RootState) => ChatMessage[];
  getActiveUsers: (state: RootState) => ChatUser[];
  getActiveUsersCount: (state: RootState) => number;
  getActiveMessageContextMenu: (state: RootState) => string;
};

const getSortedMessages = (messages: Message[]): Message[] => {
  return messages.sort((a, b) => {
    const aTs = parseInt("" + a.timeStamp);
    const bTs = parseInt("" + b.timeStamp);

    if (aTs > bTs) {
      return 1;
    }

    if (aTs < bTs) {
      return -1;
    }

    return 0;
  });
};

const getters: GetterTree<RootState, RootState> & Getters = {
  getIsLoading: (state: RootState) => state.isLoading,
  getIsloggedIn: (state: RootState) => state.isLoggedIn,
  getLoginToken: (state: RootState) => state.loginToken,
  getActiveRoom: (state: RootState) => state.activeRoom,
  getCurrentChatMode: (state: RootState) => {
    return state.activeRoom?.mode as ChatRoomMode;
  },
  getAccessToken: (state: RootState) => state.accessToken,
  getActiveMessageContextMenu: (state: RootState) =>
    state.activeMessageContextMenu,
  getUser: (state: RootState) => state.user,
  getUserId: (state: RootState) => state.user?.uuid,
  getUserName: (state: RootState) => state.user?.name,
  getMessages: (state: RootState) => state.messages,
  getTopics: (state: RootState) => state.topics,
  getAllMessages: (state: RootState) => {
    const filtered = state.messages.filter(m => {
      return m.state !== ChatMessageState.DELETED;
    });

    return getSortedMessages(filtered);
  },
  getActiveUsers: (state: RootState) => state.activeUsers,
  getActiveUsersCount: (state: RootState) => state.activeUsers.length,
  getVisibleMessages: (state: RootState) => {
    const visible = [ChatMessageState.ENABLED, ChatMessageState.BLOCKED];
    const serverMessages = state.serverMessages;

    const filteredMessages = state.messages.filter((m: ChatMessage) => {
      if (m.type === MessageType.SERVER_MESSAGE) {
        return true;
      }

      const userUuid = state.user?.uuid;
      if (
        userUuid &&
        userUuid === m.user?.uuid &&
        m.state === ChatMessageState.WAITING
      ) {
        return true;
      }

      return visible.includes(m.state as ChatMessageState);
    });

    const mergedMessages = filteredMessages.concat(serverMessages);
    return getSortedMessages(mergedMessages);
  },
  getIsModMode: (state: RootState) => {
    if (!state.user || !state.user.roles) {
      return false;
    }

    const roles = state.user.roles ?? [];
    return (
      roles.includes(UserRole.MODERATOR) || roles.includes(UserRole.SPEAKER)
    );
  }
};

export default getters;
