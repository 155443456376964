import { LocaleMessages, VueMessageType } from "vue-i18n";

const messages: LocaleMessages<VueMessageType> = {
  en: {
    message: {
      error: "Error: ",
      send: "Send",
      save: "Save",
      start: "Start",
      stop: "Stop",
      cancel: "Cancel",
      cancelSurvey: "Cancel",
      delete: "Delete",
      wrongCode: "Invalid code",
      errorOccurred: "Whoops, something went wrong. Please try again",
      errorSocketConnection:
        "There was an issue with your connection, please log in again",
      chatRoomExpired: "The event is already expired",
      actionDeleteMessage: "Delete message",
      actionBlockMessage: "Block message",
      actionUnblockMessage: "Unblock message",
      actionBanUser: "Ban user",
      actionBlockUser: "Block user",
      actionUnblockUser: "Unblock user",
      actionClearChat: "Clear entire chat",
      joinChatRoom: "Join chat room",
      exitChatRoom: "Exit chat room",
      enterLoginCodeHere: "Please enter Login-Code here:",
      confirmLogout: "Are you sure you want to sign out?",
      confirmChatClear:
        "Are you sure you want to clear the whole chat history?",
      confirmYes: "Yes",
      confirmNo: "No",
      time: "at",
      titleConfirmation: "Confirmation",
      confirmDeleteEnterText: "Delete",
      confirmDeleteEnterTextPrompt: 'Please enter "Delete"',
      chatModTabNormal: "All messages",
      chatModTabApprove: "Approve messages",
      usersTabTitle: "Users",
      chatTabTitle: "Chat",
      approvalTabTitle: "Approval",
      chatWriteMessagePrompt: "Write a message...",
      chatInputBanned: "The input has been blocked ...",
      chatIsCurrentlyInactive: "The chat is currently inactive ...",
      topicsTabTitle: "Topics",
      topicsMarkMessageAsTopic: "Mark as topic",
      topicCreated: "Created a topic, go to topics to start the topic",
      surveyTabTitle: "Surveys",
      surveyCreateSurvey: "Create new survey",
      surveyAskQuestion: "Ask a question",
      surveyChoseSurveyMode: "Users can answer as follows:",
      surveyModeTitle: "Survey type:",
      surveySurveyModeMultiple: "Multipe choice",
      surveySurveyModeFreeText: "Free text",
      surveyChoice: "or",
      surveyQuestionTitle: "Question",
      surveyOptionTitle: "Option",
      surveyAddAnswer: "Additional answer",
      surveyRemoveAnswer: "Remove answer",
      surveyChooseAnswer: "Choose an answer",
      surveyTypeAnswer: "Please enter your answer",
      surveyStateLabel: {
        opened: "active",
        waiting: "waiting",
        cancelled: "cancelled",
        closed: "closed",
        deleted: "deleted"
      },
      surveyStatisticsTotalTeil1: "There are ",
      surveyStatisticsTotalTeil2: "Answers:",
      noSurveyStatisticsAnswers: "There are no answers yet",
      surveyRemotePageTitle: "Survey Monitor",
      messageWasBlocked: "This message has been blocked",
      settings: "Settings",
      settingsRemoteSurveys: "Survey Monitor",
      settingsChatActiveState: "Chat activate",
      settingsFreeChat: "Free chat",
      settingsSlowChatMode: "Slow chat",
      settingsFontSize: "Adjust Font size",
      userRole: "Host",
      user: "User",
      userDisconnected: "disconnected",
      scrollDownInfo: "scroll down"
    }
  },
  de: {
    message: {
      error: "Fehler: ",
      send: "Abschicken",
      save: "Speichern",
      start: "Start",
      stop: "Stop",
      cancel: "Löschen",
      cancelSurvey: "Abbrechen",
      delete: "Löschen",
      wrongCode: "Ungültiger Code",
      errorOccurred:
        "Ups, da ist etwas schief gelaufen. Versuchen Sie es bitte erneut.",
      errorSocketConnection:
        "Es ist ein Problem mit der Verbindung aufgetreten, bitte melden Sie sich erneut an",
      chatRoomExpired: "Die Veranstaltung ist bereits beendet",
      actionDeleteMessage: "Nachricht löschen",
      actionBlockMessage: "Nachricht blocken",
      actionUnblockMessage: "Nachricht anzeigen",
      actionBanUser: "Benutzer bannen",
      actionBlockUser: "Benutzer blockieren",
      actionUnblockUser: "Benutzer entblocken",
      actionClearChat: "Ganzen Chat löschen",
      joinChatRoom: "Chat-Raum betreten",
      exitChatRoom: "Chat verlassen",
      enterLoginCodeHere: "Geben Sie bitte hier den Login-Code ein:",
      confirmLogout: "Möchten Sie sich wirklich abmelden?",
      confirmChatClear: "Möchten Sie wirklich alle Nachrichten löschen?",
      confirmYes: "Ja",
      confirmNo: "Nein",
      time: "um",
      titleConfirmation: "Bestätigen",
      confirmDeleteEnterText: "Löschen",
      confirmDeleteEnterTextPrompt: 'Geben Sie bitte "Löschen" ein',
      chatModTabNormal: "Alle Nachrichten",
      chatModTabApprove: "Nachrichten freigeben",
      usersTabTitle: "Benutzer",
      chatTabTitle: "Chat",
      approvalTabTitle: "Freigabe",
      chatWriteMessagePrompt: "Schreiben Sie...",
      chatInputBanned: "Die Eingabe wurde gesperrt ...",
      chatIsCurrentlyInactive: "Der Chat ist gerade inaktiv ...",
      topicsTabTitle: "Themen",
      topicsMarkMessageAsTopic: "Als Thema markieren",
      topicCreated:
        "Thema wurde erstellt, gehen Sie zu Themen um es zu starten",
      surveyTabTitle: "Umfragen",
      surveyCreateSurvey: "Neue Umfrage erstellen",
      surveyAskQuestion: "Frage stellen",
      surveyChoseSurveyMode: "Die Nutzer können wie folgt antworten:",
      surveyModeTitle: "Umfragetyp:",
      surveySurveyModeMultiple: "Mehrere Antwortmöglichkeiten",
      surveySurveyModeFreeText: "Freitext Antwort",
      surveyChoice: "oder",
      surveyQuestionTitle: "Frage",
      surveyOptionTitle: "Antwort",
      surveyAddAnswer: "Weitere Antwort hinzufügen",
      surveyRemoveAnswer: "Eine Antwort entfernen",
      surveyChooseAnswer: "Wählen Sie eine Antwort",
      surveyTypeAnswer: "Geben Sie eine Antwort ein",
      surveyStateLabel: {
        opened: "aktiv",
        waiting: "wartend",
        cancelled: "abgebrochen",
        closed: "beendet",
        deleted: "gelöscht"
      },
      surveyStatisticsTotalTeil1: "Es gibt ",
      surveyStatisticsTotalTeil2: "Antworten",
      noSurveyStatisticsAnswers: "Es liegen noch keine Antworten vor",
      surveyRemotePageTitle: "Umfrage-Monitor",
      messageWasBlocked: "Diese Nachricht wurde blockiert!",
      settings: "Einstellungen",
      settingsRemoteSurveys: "Umfrage-Monitor",
      settingsChatActiveState: "Chat aktivieren",
      settingsFreeChat: "Alle Nachrichten freigeben",
      settingsSlowChatMode: "Langsamer Chat",
      settingsFontSize: "Schriftgröße anpassen",
      userRole: "Moderator",
      user: "Teilnehmer",
      userDisconnected: "abgemeldet",
      scrollDownInfo: "Nach unten springen"
    }
  }
};
export default messages;
