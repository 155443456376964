<template>
  <div class="settings-button">
    <Button
      type="Button"
      style="padding: 3px"
      icon="pi pi-sign-out"
      class="p-button p-button-outlined sign-out"
      @click="confirmLogout()"
    ></Button>

    <Button
      v-if="isMod()"
      type="Button"
      style="padding: 3px"
      icon="pi pi-cog"
      class="p-button-text cog"
      @click="toggle"
    ></Button>
  </div>

  <OverlayPanel
    ref="settingsPanel"
    :ismissable="true"
    :show-close-icon="false"
    :base-z-index="5000"
  >
    <h3 class="heading">{{ t("message.settings") }}</h3>
    <br />

    <!-- Toggle chat activation state -->
    <div v-if="isMod()" class="p-grid">
      <div class="p-col-8">
        {{ t("message.settingsChatActiveState") }}
      </div>
      <div class="p-col-4 p-text-right">
        <InputSwitch
          :model-value="isChatActive"
          @click="toggleChatActiveState(!isChatActive)"
        ></InputSwitch>
      </div>
    </div>

    <div v-if="isMod()" class="p-grid">
      <div class="p-col-8">
        {{ t("message.settingsFreeChat") }}
      </div>
      <div class="p-col-4 p-text-right">
        <InputSwitch
          :model-value="currentChatMode === ChatRoomMode.NORMAL"
          @click="changedChatMode(currentChatMode === ChatRoomMode.NORMAL)"
        ></InputSwitch>
      </div>
    </div>

    <div v-if="isMod()" class="p-grid">
      <div class="p-col-12">
        <div class="p-fluid">
          <Button
            class="p-mt-2 p-button p-button p-button-secondary btn-setting-block"
            icon="pi pi-chart-bar"
            :label="t('message.settingsRemoteSurveys')"
            @click="openSurveyRemoteView()"
          ></Button>
        </div>
      </div>
    </div>

    <div v-if="isMod()" class="p-grid">
      <div class="p-col-12">
        <div class="p-fluid">
          <Button
            class="p-mt-2 p-button p-button p-button-danger btn-setting-block"
            icon="pi pi-trash"
            :label="t('message.actionClearChat')"
            @click="confirmChatClear()"
          ></Button>
        </div>
      </div>
    </div>
  </OverlayPanel>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from "vue";
import { useI18n } from "vue-i18n";
import OverlayPanel from "primevue/overlaypanel";
import InputSwitch from "primevue/inputswitch";
import Button from "primevue/button";
import {
  ChatRoomMode,
  ChatUser,
  SettingKey,
  SettingsEntry,
  UserRole
} from "@/store/types";
import { useConfirm } from "primevue/useconfirm";

export default defineComponent({
  name: "SettingsPanel",
  components: {
    OverlayPanel,
    InputSwitch,
    Button
  },
  props: {
    currentUser: {
      type: Object as PropType<ChatUser>,
      required: true
    },
    currentChatMode: {
      type: String as PropType<ChatRoomMode>,
      default: ChatRoomMode.NORMAL
    },
    isChatActive: {
      type: Boolean,
      required: true
    },
    onSettingChanged: {
      type: Function as PropType<(entry: SettingsEntry) => void>,
      required: true
    },
    onLogout: {
      type: Function as PropType<() => void>,
      required: true
    },
    onOpenSurveyRemoteView: {
      type: Function as PropType<() => void>,
      required: true
    },
    onClearRoom: {
      type: Function as PropType<() => void>,
      required: true
    }
  },
  setup(props) {
    const { t } = useI18n();
    const confirm = useConfirm();
    const settingsPanel = ref<OverlayPanel>();

    const confirmLogout = () => {
      confirm.require({
        group: "confirmation",
        message: t("message.confirmLogout"),
        header: t("message.titleConfirmation"),
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        acceptLabel: t("message.confirmYes"),
        rejectLabel: t("message.confirmNo"),
        accept: () => {
          props.onLogout();
        }
      });
    };

    const confirmChatClear = () => {
      confirm.require({
        group: "extra-check",
        message: t("message.confirmChatClear"),
        header: t("message.titleConfirmation"),
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        acceptLabel: t("message.confirmYes"),
        rejectLabel: t("message.confirmNo"),
        accept: () => {
          props.onClearRoom();
        }
      });
    };

    const hasRole = (role: UserRole) => {
      if (!props.currentUser.roles) {
        return false;
      }

      return props.currentUser.roles.includes(role);
    };

    return {
      ChatRoomMode: ChatRoomMode,
      t: t,
      settingsPanel: settingsPanel,
      hasRole: hasRole,
      confirmLogout: confirmLogout,
      confirmChatClear: confirmChatClear,
      isMod: () => {
        return hasRole(UserRole.SPEAKER) || hasRole(UserRole.MODERATOR);
      },
      toggle: (event: Event) => {
        settingsPanel.value?.toggle(event);
      },
      toggleChatActiveState: (isActive: boolean) => {
        console.log("toggle", isActive);
        props.onSettingChanged({
          key: SettingKey.CHAT_ACTIVATION_STATE,
          value: isActive
        });
      },
      changedChatMode: (isAutoApproval: boolean) => {
        const mode: ChatRoomMode = isAutoApproval
          ? ChatRoomMode.STRICT
          : ChatRoomMode.NORMAL;

        props.onSettingChanged({
          key: SettingKey.CHAT_MODE,
          value: mode
        });
      },
      openSurveyRemoteView: () => {
        settingsPanel.value?.hide();
        props.onOpenSurveyRemoteView();
      }
    };
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../theme/variables.scss";

.p-col-6 {
  margin: 0 auto;
}

.sign-out {
  color: $color-btn-blue-normal;
}

.heading {
  margin: 0 auto;
  padding: 10px 0;
}

.btn-setting-block {
  white-space: nowrap;
  ::v-deep(.p-button-icon-left) {
    margin-right: 0.5rem;
  }
}
</style>
