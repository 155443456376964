import { ActionTypes } from "@/store/actions";
import { MutationTypes } from "@/store/mutations";
import {
  AnyObject,
  ChatMessage,
  ChatRoom,
  ChatSurvey,
  ChatTopic,
  ChatUser,
  FlashMessage,
  ServerMessage,
  Store
} from "@/store/types";
import { SocketMessage } from "@/utils/socket";

export default function createWebSocketPlugin(socket: SocketIOClient.Socket) {
  return (store: Store) => {
    socket.on("connect", () => {
      store.commit(MutationTypes.SET_CHAT_CONNECTED, true);
    });
    socket.on("disconnect", () => {
      store.commit(MutationTypes.SET_CHAT_CONNECTED, false);
    });
    socket.on("exception", (error: AnyObject) => {
      console.log("exception", error);
      const flashMessage: FlashMessage = {
        type: "error",
        title: "message.error",
        message: "message.errorSocketConnection",
        duration: 3000,
        onShown: () => {
          store.dispatch(ActionTypes.LOGOUT).then(() => {
            window.location.reload();
          });
        }
      };
      store.commit(MutationTypes.ADD_FLASH_MESSAGE, flashMessage);
    });
    socket.on("error", (error: AnyObject) => {
      if (error.type === "authError") {
        console.error(error);
        store.dispatch(ActionTypes.LOGOUT).then(() => {
          window.location.reload();
        });
      }
    });

    socket.on("message", (message: SocketMessage) => {
      const payload = message.payload;
      payload.type = message.type;
      store.commit(MutationTypes.SET_MESSAGE, payload as ChatMessage);
    });
    socket.on("server-message", (message: SocketMessage) => {
      const payload = message.payload;
      payload.type = message.type;
      store.commit(MutationTypes.ADD_SERVER_MESSAGE, payload as ServerMessage);
    });
    socket.on("update-profile", (user: ChatUser) => {
      if (user) {
        store.commit(MutationTypes.SET_USER, user);
      }
    });
    socket.on("update-message", (message: AnyObject) => {
      store.commit(MutationTypes.UPDATE_MESSAGE, message.payload);
    });
    socket.on("update-messages", (message: ChatMessage[]) => {
      store.commit(MutationTypes.SET_MESSAGES, message);
    });
    socket.on("update-chat-room", (room: ChatRoom) => {
      store.commit(MutationTypes.SET_CHAT_ACTIVE_ROOM, room);
    });
    socket.on("update-survey", (survey?: ChatSurvey) => {
      if (!survey) {
        store.dispatch(ActionTypes.FETCH_SURVEYS);
        return;
      }

      store.commit(MutationTypes.UPDATE_SURVEY, survey);
    });
    socket.on("delete-survey", (survey?: ChatSurvey) => {
      if (!survey) {
        store.dispatch(ActionTypes.FETCH_SURVEYS);
        return;
      }

      store.commit(MutationTypes.DELETE_SURVEY, survey);
    });
    socket.on("update-topic", (topic?: ChatTopic) => {
      if (!topic) {
        store.dispatch(ActionTypes.FETCH_TOPICS);
        return;
      }
      store.commit(MutationTypes.UPDATE_TOPIC, topic);
    });
    socket.on("delete-topic", (topic?: ChatTopic) => {
      if (!topic) {
        store.dispatch(ActionTypes.FETCH_TOPICS);
        return;
      }

      store.commit(MutationTypes.DELETE_TOPIC, topic);
    });
    socket.on("users", (users: ChatUser[]) => {
      store.commit(MutationTypes.SET_ACTIVE_USERS, users);
    });
    socket.on("update-user", (user: ChatUser) => {
      store.commit(MutationTypes.UPDATE_ACTIVE_USER, user);
      store.commit(MutationTypes.UPDATE_USER_MESSAGES, user);
    });
    socket.on("active-survey", (survey: ChatSurvey | null) => {
      store.commit(MutationTypes.SET_ACTIVE_SURVEY, survey);
    });
    socket.on("active-topic", (topic?: ChatTopic) => {
      store.commit(MutationTypes.SET_ACTIVE_TOPIC, topic ?? undefined);
    });
    socket.on("survey-statistic", (payload: ChatSurvey) => {
      const updatedSurveys = (store.state.surveys ?? []).map(survey => {
        if (survey.uuid === payload.uuid) {
          return payload;
        }
        return survey;
      });

      store.commit(MutationTypes.SET_SURVEYS, updatedSurveys);
      store.dispatch(ActionTypes.FETCH_SURVEYS_REMOTE_VIEW);
    });
    store.subscribe(mutation => {
      if (mutation.type === MutationTypes.SET_CHAT_ACTIVE_ROOM) {
        const opts: Partial<SocketIOClient.ConnectOpts> = {
          ...socket.io.opts,
          query: {
            accessToken: store.state.accessToken,
            chatRoomUuid: store.state.activeRoom?.uuid
          }
        };
        socket.io.opts = opts;

        socket.connect();
      }
    });
    store.subscribeAction({
      after: action => {
        if (action.type === ActionTypes.SEND_MESSAGE) {
          socket.emit("message", action.payload);
        }
      }
    });
  };
}
