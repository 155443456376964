import axios from "axios";
import { ChatRoom, ChatUser } from "@/store/types";

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL
});
export interface LoginResponse {
  accessToken: string;
  user: ChatUser;
  userUuid: string;
  username: string;
  activeChatRoomUuid: string;
  activeRoom: ChatRoom;
}

export default api;
