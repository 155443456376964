import { AnyObject } from "@/store/types";
import io from "socket.io-client";

export enum MessageType {
  SERVER_MESSAGE = "server",
  CHAT_MESSAGE = "chat",
  ERROR_MESSAGE = "error",
  USER_JOINED = "user"
}

export interface SocketMessage {
  payload: AnyObject;
  type: MessageType;
  timestamp: string;
}

const socketOptions: Partial<SocketIOClient.ConnectOpts> = {
  autoConnect: false,
  transports: ["websocket", "polling"]
};

export default io(process.env.VUE_APP_SOCKET_URL, socketOptions);
