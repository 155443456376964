<template>
  <div class="container-user-avatar">
    <div class="user-avatar-bubble" :style="{ background: getBubbleColored() }">
      <u v-if="moderator">
        <i class="pi pi-user"></i>
      </u>
      <span v-if="!moderator">{{ user.initials }}</span>
    </div>

    <span v-if="user.usernameChanged">
      <span>{{ user.name }}</span>
    </span>
    <span v-else-if="!user.usernameChanged">
      <span>{{ t("message.user") }}</span>
    </span>
    <span class="tag"> #{{ user.tagNumber }} </span>
  </div>
</template>

<script lang="ts">
import { ChatUser, UserRole } from "@/store/types";
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "UserAvatar",
  components: {},
  props: {
    user: {
      type: Object as PropType<ChatUser>,
      required: true
    }
  },
  setup(props) {
    const { t } = useI18n();
    const moderator = props.user.roles?.includes(UserRole.MODERATOR);

    return {
      t: t,
      moderator,

      getBubbleColored() {
        const color = props.user;

        if (!color) {
          return "";
        }

        return "#" + color.colorHexNumber;
      }
    };
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../theme/variables.scss";
@import "../theme/mixins.scss";
.container-user-avatar {
  padding: 0 5px 5px 5px;
  hyphens: auto;
  font-size: $font-size-small-medium;
  letter-spacing: 0.5px;

  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .user-avatar-bubble {
    color: $color-white;
    display: inline-block;
    text-align: center;
    line-height: 1.8rem;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 100px;
    margin-right: 5px;
  }

  .user-avatar-bubble .pi {
    font-size: 0.9rem;
  }

  .tag {
    color: $color-btn-gray-normal;
    font-size: $font-size-base;
  }
}
</style>
