<template>
  <div
    v-if="survey.type === SurveyType.MULTIPLE_CHOICE"
    class="survey-answer-form"
  >
    <div
      v-for="(item, key) in survey.options"
      :key="key"
      class="answer p-col-12"
    >
      <RadioButton
        :id="'answer' + key"
        v-model="answerModel"
        name="answer"
        :value="key"
      ></RadioButton>
      <label class="p-ml-2" :for="'answer' + key">{{ item }}</label>
    </div>
    <div class="survey-answer-btn p-d-flex p-jc-between">
      <Button
        :disabled="!canSubmit()"
        class="p-button p-button-info btn-send-survey"
        :label="t('message.send')"
        @click="submitAnswer()"
      ></Button>

      <Button
        class="p-button p-button-danger p-button-text"
        :label="t('message.cancelSurvey')"
        @click="cancel()"
      ></Button>
    </div>
  </div>

  <div
    v-if="survey.type === SurveyType.FREE_TEXT"
    class="survey-answer-form"
    :class="getClasses()"
  >
    <InputText
      v-model="answerText"
      :placeholder="t('message.chatWriteMessagePrompt')"
      class="input-form"
      :maxlength="textMaxLength"
      @keyup.enter="submitAnswer()"
    />
    <div class="charcount">{{ answerText.length }}/{{ textMaxLength }}</div>
    <div class="p-grid p-jc-around">
      <Button
        :disabled="!canSubmit()"
        class="p-button p-button-info send-survey"
        :label="t('message.send')"
        @click="submitAnswer()"
      ></Button>

      <Button
        class="p-button p-button-text p-button-danger cancel-survey"
        :label="t('message.cancel')"
        @click="cancel()"
      ></Button>
    </div>
  </div>
</template>

<script lang="ts">
import { ChatSurvey, SurveyType } from "@/store/types";
import { defineComponent, PropType, ref } from "vue";
import { useI18n } from "vue-i18n";
import Button from "primevue/button";
import RadioButton from "primevue/radiobutton";
import InputText from "primevue/inputtext";

const INPUT_WARNING_THRESHOLD_NONE = 50;
const SURVEY_TEXT_MAX_LENGTH = 150;

export default defineComponent({
  name: "SurveyAnswerForm",
  components: {
    Button,
    RadioButton,
    InputText
  },
  props: {
    survey: {
      type: Object as PropType<ChatSurvey>,
      required: true
    },
    onAnswered: {
      type: Function as PropType<(survey: ChatSurvey, answer: string) => void>,
      required: true
    },
    onCancel: {
      type: Function as PropType<() => void>,
      required: false,
      default: undefined
    }
  },
  setup: props => {
    const { t } = useI18n();
    const answerModel = ref(-1);
    const answerText = ref<string>("");

    const canSubmit = () => {
      if (
        props.survey.type === SurveyType.MULTIPLE_CHOICE &&
        answerModel.value === -1
      ) {
        return false;
      }
      if (
        (props.survey.type === SurveyType.FREE_TEXT &&
          answerText.value.trim() === "") ||
        (props.survey.type === SurveyType.FREE_TEXT &&
          answerText.value.length >= SURVEY_TEXT_MAX_LENGTH)
      ) {
        return false;
      }
      return true;
    };

    const getClasses = () => {
      return {
        none: answerText.value.length <= INPUT_WARNING_THRESHOLD_NONE,
        warning: answerText.value.length > SURVEY_TEXT_MAX_LENGTH - 10,
        error: answerText.value.length >= SURVEY_TEXT_MAX_LENGTH
      };
    };

    const submitAnswer = () => {
      if (!canSubmit()) {
        return;
      }
      const answerKey = answerModel.value;
      if (
        props.survey.type === SurveyType.MULTIPLE_CHOICE &&
        answerKey !== -1
      ) {
        props.onAnswered(props.survey, props.survey.options[answerKey]);
      }
      if (
        props.survey.type === SurveyType.FREE_TEXT &&
        answerText.value !== ""
      ) {
        props.onAnswered(props.survey, answerText.value);
      }
    };

    const cancel = () => {
      answerText.value = "";
      answerModel.value = -1;
      props.onCancel();
    };
    return {
      SurveyType,
      t,
      answerModel,
      answerText,
      textMaxLength: SURVEY_TEXT_MAX_LENGTH,
      getClasses,
      canSubmit,
      submitAnswer,
      cancel
    };
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../theme/variables.scss";
.input-form {
  width: 100%;
  margin-bottom: 25px;
}
.answer {
  padding: 10px;
}
.survey-answer-btn {
  margin: 10px 0 0;

  @media (min-width: 320px) and (max-width: 370px) {
    .btn-send-survey {
      ::v-deep(.p-button-label) {
        margin-left: 0 !important;
      }
    }
  }
}
.p-button {
  border-radius: $border-radius-medium;
}

.p-inputtext.p-component.input-form {
  border-radius: $border-radius-large;
}
.survey-answer-form {
  .charcount {
    position: absolute;
    right: 4px;
    top: 1px;
    opacity: 0.9;
    font-size: 12px;
    color: $border-color-light-gray;
  }
  &.warning {
    .p-inputtext {
      border: 1px solid $color-btn-red-hover;
    }
    .charcount {
      color: $color-btn-red-disabled;
    }
  }
  &.error {
    .p-inputtext {
      border: 1px solid $color-btn-red-hover;
    }
    .charcount {
      color: $color-btn-red-hover;
    }
  }
  &.none {
    .charcount {
      display: none;
    }
  }
}
.survey-answer-form {
  @media (min-width: 320px) and (max-width: 375px) {
    .button-start-survey {
      ::v-deep(.p-button-label) {
        margin-left: 0;
      }
    }
    .button-stop-survey {
      ::v-deep(.p-button-label) {
        margin-left: 0;
      }
    }
  }
}
</style>
