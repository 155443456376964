
import { defineComponent, PropType, ref, watch } from "vue";
import { AnyObject, ChatUser, UserRole } from "@/store/types";
import ContextMenu from "primevue/contextmenu";
import { useI18n } from "vue-i18n";

const buildContextMenu = (props: AnyObject, t: Function) => {
  const user: ChatUser = props.user;
  const menu = [];

  if (user.isBanned) {
    menu.push({
      label: t("message.actionUnblockUser"),
      icon: "pi pi-fw pi-check-circle",
      command: () => {
        props.onUserUnblock(user);
      }
    });
  } else {
    menu.push({
      label: t("message.actionBlockUser"),
      icon: "pi pi-fw pi-ban",
      command: () => {
        props.onUserBlock(user);
      }
    });
  }

  return menu;
};

export default defineComponent({
  name: "UserItem",
  components: {
    ContextMenu: ContextMenu
  },
  props: {
    user: {
      type: Object as PropType<ChatUser>,
      required: true
    },

    onUserBlock: {
      type: Function as PropType<(user: ChatUser) => {}>,
      required: true
    },
    onUserUnblock: {
      type: Function as PropType<(user: ChatUser) => {}>,
      required: true
    }
  },
  setup: (props: AnyObject) => {
    const { t } = useI18n();
    const contextModMenu = ref<ContextMenu>();
    const menuModel = ref(buildContextMenu(props, t));

    watch(
      () => props.user,
      () => {
        menuModel.value = buildContextMenu(props, t);
      },
      { deep: true, flush: "post" }
    );

    return {
      t: t,
      contextModMenu: contextModMenu,
      menuModel: menuModel,

      openContextMenu(event: Event) {
        contextModMenu.value?.toggle(event);
      },
      getTags() {
        const tags = [];

        if (props.user.roles?.includes(UserRole.MODERATOR)) {
          tags.push("Moderator");
        }

        if (props.user.roles?.includes(UserRole.SPEAKER)) {
          tags.push("Speaker");
        }

        if (!props.user.isConnected) {
          tags.push(t("message.userDisconnected"));
        }

        return tags;
      }
    };
  }
});
