
import { ChatTopic, UserRole } from "@/store/types";
import { formatDate } from "@/utils/date";
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "ActiveTopic",
  components: {},
  props: {
    topic: {
      type: Object as PropType<ChatTopic>,
      required: true
    }
  },
  setup: props => {
    const { t } = useI18n();
    const isModerator = props.topic.message?.user?.roles?.includes(
      UserRole.MODERATOR
    );

    return {
      t,
      isModerator,
      formatTime(timeStamp: string) {
        return formatDate(new Date(parseInt(timeStamp)), "hh:mm");
      },
      getClasses() {
        return {
          isModerator
        };
      },
      getBubbleColored() {
        const color = props.topic.message?.user;

        if (!color) {
          return "";
        }

        return "#" + color.colorHexNumber;
      }
    };
  },
  data() {
    return {
      answer: -1
    };
  },
  methods: {}
});
