<template>
  <div class="active-topic">
    <div class="header">
      <i class="topic-icon pi pi-tags"></i>
      <div
        class="user-avatar-bubble"
        :style="{ background: getBubbleColored() }"
      >
        <u v-if="isModerator">
          <i class="pi pi-user"></i>
        </u>
        <span v-if="!isModerator">{{ topic.message?.user?.initials }}</span>
      </div>

      <div
        v-if="topic.message.user?.name"
        class="username"
        :class="getClasses()"
      >
        {{ topic.message.user.name }}
        <div class="time">
          {{ t("message.time") }} {{ formatTime(topic.message.timeStamp) }}
        </div>
      </div>
    </div>
    <div class="topic-message">{{ topic.message.message }}</div>
  </div>
</template>

<script lang="ts">
import { ChatTopic, UserRole } from "@/store/types";
import { formatDate } from "@/utils/date";
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "ActiveTopic",
  components: {},
  props: {
    topic: {
      type: Object as PropType<ChatTopic>,
      required: true
    }
  },
  setup: props => {
    const { t } = useI18n();
    const isModerator = props.topic.message?.user?.roles?.includes(
      UserRole.MODERATOR
    );

    return {
      t,
      isModerator,
      formatTime(timeStamp: string) {
        return formatDate(new Date(parseInt(timeStamp)), "hh:mm");
      },
      getClasses() {
        return {
          isModerator
        };
      },
      getBubbleColored() {
        const color = props.topic.message?.user;

        if (!color) {
          return "";
        }

        return "#" + color.colorHexNumber;
      }
    };
  },
  data() {
    return {
      answer: -1
    };
  },
  methods: {}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../theme/variables.scss";
.active-topic {
  background-color: $color-active-topic;
  padding: 7px 10px 9px;
  border: none;
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  border-radius: $border-radius-overlay;
  top: -20px;
  z-index: 80;

  max-width: 1000px;
  margin: 0 auto 5px;

  @media (min-width: 425px) {
    top: -10px;
  }
  @media (min-width: 1024px) {
    top: 0;
  }

  .header {
    display: flex;
    align-items: center;
  }
  .user-avatar-bubble {
    color: $color-white;
    display: inline-block;
    text-align: center;
    line-height: 1.8rem;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;
    margin-right: 5px;
  }
  .username {
    line-height: 1.5;
    display: flex;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    .time {
      margin-left: 10px;
      text-align: left;
      font-size: 0.59rem;
      letter-spacing: 0.4px;
      color: $color-btn-gray-normal;
      line-height: 2.3;
      text-overflow: ellipsis;
      overflow: hidden;
      @media (min-width: 375px) {
        font-size: $font-size-small;
      }
    }
    &.isModerator {
      font-weight: 600;
      align-items: baseline;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
.topic-icon {
  color: $color-white;
  background: $color-btn-blue-normal;
  border-radius: $border-radius;
  padding: 7px;
  margin-right: 10px;
  font-size: 1rem;
  line-height: 1;
}
.topic-message {
  margin: 5px 0 0 40px;
  font-weight: 500;
  word-break: break-word;
}
</style>
