
import {
  AnyObject,
  ChatMessage,
  ChatMessageContextAction,
  ChatMessageState,
  ChatUser,
  UserRole
} from "@/store/types";
import { defineComponent, onUnmounted, PropType, ref, watch } from "vue";
import ContextMenu from "primevue/contextmenu";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
import { MutationTypes } from "@/store/mutations";

const buildContextMenu = (
  props: AnyObject,
  t: Function,
  currentUser?: ChatUser
) => {
  const message: ChatMessage = props.message;

  const isSpeaker = currentUser?.roles.includes(UserRole.SPEAKER);
  const isMod = currentUser?.roles.includes(UserRole.MODERATOR);
  const isAdmin = isSpeaker || isMod;

  const isOwner = currentUser?.uuid === message.user?.uuid;

  const userMenu: AnyObject[] = [
    {
      label: t("message.actionDeleteMessage"),
      icon: "pi pi-fw pi-trash",
      visible: isAdmin || isOwner,
      command: () => {
        props.onAction(ChatMessageContextAction.DELETE_MESSAGE, message);
      }
    }
  ];

  const adminMenu: AnyObject[] = isAdmin
    ? [
        {
          separator: true,
          visible: true
        },
        {
          label: t("message.topicsMarkMessageAsTopic"),
          icon: "pi pi-fw pi-tags",
          visible: true,
          disabled: message.state !== ChatMessageState.ENABLED,
          command: () => {
            props.onAction(ChatMessageContextAction.MARK_TOPIC, message);
          }
        },
        {
          label: t("message.actionBlockMessage"),
          icon: "pi pi-eye-slash",
          visible: message.state === ChatMessageState.ENABLED,
          command: () => {
            props.onAction(ChatMessageContextAction.BLOCK_MESSAGE, message);
          }
        },
        {
          label: t("message.actionUnblockMessage"),
          visible: message.state !== ChatMessageState.ENABLED,
          icon: "pi pi-eye",
          command: () => {
            props.onAction(ChatMessageContextAction.APPROVE_MESSAGE, message);
          }
        },
        {
          label: t("message.actionBanUser"),
          icon: "pi pi-fw pi-ban",
          visible: true,
          command: () => {
            props.onAction(ChatMessageContextAction.BAN_USER, message);
          }
        }
      ]
    : [];

  userMenu.push(...adminMenu);
  return userMenu.filter(m => m.visible);
};

export default defineComponent({
  name: "ChatContextMenu",
  components: {
    ContextMenu: ContextMenu
  },
  props: {
    message: {
      type: Object as PropType<ChatMessage>,
      required: true
    },
    onAction: {
      type: Function as PropType<
        (action: ChatMessageContextAction, message: ChatMessage) => void
      >,
      required: true
    }
  },
  emits: ["chat-context-clicked"],
  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const contextMenu = ref<ContextMenu>();
    const menu = ref(buildContextMenu(props, t, store.state.user));

    const w1 = watch(
      () => props.message,
      () => {
        menu.value = buildContextMenu(props, t, store.state.user);
      }
    );

    const w2 = watch(
      () => store.getters.getActiveMessageContextMenu,
      () => {
        const active = store.getters.getActiveMessageContextMenu;
        if (active !== props.message.uuid) {
          contextMenu?.value?.hide();
        }
      }
    );

    onUnmounted(() => {
      w1();
      w2();
    });

    return {
      t: t,
      ChatMessageState: ChatMessageState,
      menuModel: menu,
      contextMenu: contextMenu,

      openContextMenu(event: Event) {
        store.commit(
          MutationTypes.SET_ACTIVE_MESSAGE_CONTEXT_MENU,
          "" + props.message.uuid
        );
        contextMenu.value?.toggle(event);
      }
    };
  }
});
