
import { defineComponent, ref } from "vue";
import Button from "primevue/button";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "ModSwitch",
  components: {
    Button
  },
  props: {
    page: {
      type: String,
      default: () => "chat"
    },
    isActive: {
      type: Boolean,
      default: () => true
    }
  },
  emits: ["page-changed"],
  setup: (props, { emit }) => {
    const { t } = useI18n();
    const activePage = ref<string>("chat");

    return {
      t,
      activePage,

      changePage(name: string) {
        activePage.value = name;
        emit("page-changed", activePage.value);
      }
    };
  }
});
