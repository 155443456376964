<template>
  <Card class="p-mb-2 survey-card">
    <template #title>
      <i class="survey-icon pi pi-chart-bar"></i>
      <div class="survey-question">{{ survey.question }}</div>
    </template>

    <template #content>
      <SurveyStatistics
        v-if="survey.statistic"
        :survey-type="survey.type"
        :survey-options="survey.options"
        :survey-stats="survey.statistic"
      />
    </template>

    <template #footer>
      <div class="p-grid">
        <div class="p-col-12 actions">
          <div class="p-mr-2 actions-left">
            <Button
              :class="{ 'p-disabled': survey.state !== 'waiting' }"
              class="p-button-sm p-button-success button-start-survey p-mr-2"
              :label="t('message.start')"
              @click="onSurveyStart(survey)"
            ></Button>

            <Button
              :class="{ 'p-disabled': survey.state !== 'opened' }"
              class="p-button-sm p-button-danger button-stop-survey"
              :label="t('message.stop')"
              @click="onSurveyCancel(survey)"
            ></Button>
          </div>

          <div class="actions-right">
            <Button
              :class="{ 'p-disabled': survey.state !== 'waiting' }"
              class="p-button-sm p-button-icon-only p-button-secondary p-mr-2"
              icon="pi pi-pencil"
              @click="onSurveyEdit(survey)"
            ></Button>

            <Button
              :class="{ 'p-disabled': survey.state === 'opened' }"
              class="p-button-sm p-button-icon-only p-button-secondary"
              icon="pi pi-trash"
              @click="onSurveyDelete(survey)"
            ></Button>
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { ChatSurvey } from "@/store/types";
import Button from "primevue/button";
import Card from "primevue/card";
import SurveyStatistics from "@/components/SurveyStatistics.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "ChatSurveyItem",
  components: {
    Button,
    SurveyStatistics,
    Card
  },
  props: {
    survey: {
      type: Object as PropType<ChatSurvey>,
      required: true
    },
    onSurveyStart: {
      type: Function as PropType<(surveys: ChatSurvey) => void>,
      required: true
    },
    onSurveyCancel: {
      type: Function as PropType<(surveys: ChatSurvey) => void>,
      required: true
    },
    onSurveyEdit: {
      type: Function as PropType<(surveys: ChatSurvey) => void>,
      required: true
    },
    onSurveyDelete: {
      type: Function as PropType<(surveys: ChatSurvey) => void>,
      required: true
    }
  },
  setup: () => {
    const { t } = useI18n();
    return {
      t
    };
  },
  methods: {
    getPercentage(answer: string): number {
      if (!this.$props.survey.statistic) {
        return parseFloat("0");
      }

      const answers = this.$props.survey.statistic.answers ?? [];
      for (let i = 0; i < answers.length; i++) {
        if (answers[i].answer === answer) {
          return Math.round(parseFloat(answers[i].percentage));
        }
      }

      return parseFloat("0");
    },
    getPercentageString(answer: string) {
      return this.getPercentage(answer) + "%";
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../theme/variables.scss";

.survey-card {
  box-shadow: none;
  background-color: $color-chat-tile;
  margin-right: 5px;
}
.survey-card .p-card-title {
  .survey-question {
    margin-top: 5px;
    white-space: pre-wrap;
    overflow: hidden;
  }
}

.survey-card {
  ::v-deep(.p-card-title) {
    font-size: $font-size-small-medium;
    display: flex;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.survey-icon {
  color: $color-white;
  background: $color-btn-blue-normal;
  border-radius: $border-radius;
  padding: 7px;
  margin-right: 10px;
  height: 30px;
  margin-top: 5px;
}
.actions {
  @media (min-width: 375px) {
    display: flex;
    flex-flow: row;
    align-items: baseline;
  }
  .actions-left {
    text-align: center;
    @media (min-width: 320px) and (max-width: 370px) {
      .button-start-survey {
        ::v-deep(.p-button-label) {
          margin-left: 0;
        }
      }
      .button-stop-survey {
        ::v-deep(.p-button-label) {
          margin-left: 0;
        }
      }
    }
  }
  .p-grid {
    justify-content: center;
  }
  .actions-right {
    text-align: center;
    margin-top: 10px;

    @media (min-width: 375px) {
      float: right;
      margin-left: auto;
    }

    @media (min-width: 320px) and (max-width: 370px) {
      .p-button-sm {
        ::v-deep(.p-button-label) {
          margin-left: 0;
        }
      }
    }

    .p-button-sm.p-button-icon-only.p-button-secondary {
      color: $color-white;
    }
  }
}
</style>
