import {
  Store as VuexStore,
  CommitOptions,
  DispatchOptions,
  ActionContext,
  MutationPayload,
  SubscribeOptions
} from "vuex";
import { Getters } from "./getters";
import { Mutations } from "./mutations";
import { Actions } from "./actions";
import { RootState } from ".";
import { MessageType } from "@/utils/socket";

export interface AnyObject {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
  socket?: SocketIOClient.Socket;
} & Omit<ActionContext<RootState, RootState>, "commit">;

export type AugmentedMutationPayload = {
  type: keyof Mutations;
} & MutationPayload;

export type Store = Omit<
  VuexStore<RootState>,
  "getters" | "commit" | "dispatch" | "subscribe"
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
} & {
  subscribe<P extends AugmentedMutationPayload>(
    fn: (mutation: P, state: RootState) => void,
    options?: SubscribeOptions
  ): () => void;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
};

export interface FlashMessage {
  type: "error" | "success" | "warn" | "info";
  message: string;
  messagePlain?: string;
  title?: string;
  duration?: number;
  onShown?: () => void;
}

export enum PageType {
  CHAT = "chat",
  SURVEYS = "surveys",
  TOPICS = "topics",
  REMOTE_SURVEYS = "remote_surveys"
}

export enum SettingKey {
  CHAT_ACTIVATION_STATE = "CHAT_ACTIVATION_STATE",
  CHAT_MODE = "chat_mode",
  SLOW_CHAT = "slow_chat",
  FONT_SIZE = "font_size"
}
export interface SettingsEntry {
  key: SettingKey;
  value: string | boolean | number;
  default?: string | boolean | number;
  titleMessageKey?: string;
}

export interface ChatUser {
  uuid: string;
  name: string;
  initials?: string;
  tagNumber?: string;
  colorHexNumber?: string;
  usernameChanged?: boolean;
  isBanned?: boolean;
  lastChatRoomUuid?: string;
  roles: UserRole[];
  isConnected: boolean;
}

export enum UserRole {
  USER = "user",
  MODERATOR = "moderator",
  SPEAKER = "speaker"
}

export interface Message {
  type?: MessageType;
  uuid?: string;
  timeStamp?: string;
  message: string;
  messageUncensored?: string;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ServerMessage extends Message {}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ErrorMessage extends Message {}
export interface ChatMessage extends Message {
  id?: string;
  uuid?: string;
  state?: string;
  user?: ChatUser;
}
export enum ChatMessageContextAction {
  MARK_TOPIC = "mark_topic",
  DELETE_MESSAGE = "delete_message",
  BLOCK_MESSAGE = "block_message",
  UNBLOCK_MESSAGE = "unblock_message",
  BAN_USER = "ban_user",
  APPROVE_MESSAGE = "approve_message",
  REVERT_APPROVE_MESSAGE = "revert_approve_message"
}
export enum ChatRoomMode {
  NORMAL = "normal",
  STRICT = "strict"
}
export interface ChatRoom {
  uuid: string;
  mode?: ChatRoomMode;
  isActive?: boolean;
}
export interface ChatTopic {
  uuid?: string;
  state?: string;
  message?: ChatMessage;
}
export enum ChatTopicState {
  WAITING = "waiting",
  OPENED = "opened",
  CLOSED = "closed",
  DELETED = "deleted"
}
export enum ChatMessageState {
  ENABLED = "enabled",
  WAITING = "waiting",
  BLOCKED = "blocked",
  DELETED = "deleted"
}

export interface ChatSurvey {
  uuid?: string;
  type: SurveyType;
  state?: SurveyState;
  question: string;
  options: string[];
  statistic?: SurveyStatistic;
}

export interface SurveyStatistic {
  total: number;
  answers: {
    total: number;
    hits: number;
    percentage: string;
    answer: string;
  }[];
}

export enum SurveyType {
  FREE_TEXT = "free_text",
  MULTIPLE_CHOICE = "multiple_choice"
}

export enum SurveyState {
  WAITING = "waiting",
  OPENED = "opened",
  CLOSED = "closed",
  CANCELLED = "cancelled",
  DELETED = "deleted"
}
