
import { defineComponent, onMounted, PropType, ref, toRefs, watch } from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { ChatSurvey, SurveyType } from "@/store/types";
import { useI18n } from "vue-i18n";

interface SurveyFormOption {
  key: string;
  label: string;
  value: string;
}

const SURVEY_TEXT_MAX_LENGTH = 150;

export default defineComponent({
  name: "SurveySubmitForm",
  components: {
    InputText,
    Button
  },
  props: {
    survey: {
      type: Object as PropType<ChatSurvey | null>,
      default: null,
      required: false
    },
    onSubmitHandler: {
      type: Function as PropType<(survey: ChatSurvey) => void>,
      required: true
    },
    onCancelHandler: {
      type: Function as PropType<() => void>,
      required: true
    },
    minOptions: {
      type: Number,
      default: () => {
        return 2;
      }
    },
    maxOptions: {
      type: Number,
      default: () => {
        return 4;
      }
    }
  },
  setup: props => {
    const { t } = useI18n();
    const { survey } = toRefs(props);
    const surveyType = ref<SurveyType>();
    const showSurveyTypeSelect = ref<boolean>(true);
    const options = ref<SurveyFormOption[]>([]);
    const question = ref("");

    const setupOptions = () => {
      if (survey.value) {
        question.value = survey.value.question;
        surveyType.value = survey.value.type;
        options.value = survey.value.options.map((option, index) => {
          const key: number = index + 1;
          return {
            key: key.toString(),
            label: `${t("message.surveyOptionTitle")} ${key}`,
            value: option
          };
        });
      } else {
        question.value = "";
        surveyType.value = undefined;
        options.value = [
          {
            key: "1",
            label: t("message.surveyOptionTitle").concat(" 1"),
            value: ""
          },
          {
            key: "2",
            label: t("message.surveyOptionTitle").concat(" 2"),
            value: ""
          }
        ];
      }
    };

    onMounted(setupOptions);
    watch(survey, setupOptions);

    const showTypeSelect = () => {
      showSurveyTypeSelect.value = true;
    };

    const choseSurveyType = (type: SurveyType) => {
      surveyType.value = type;
      showSurveyTypeSelect.value = false;
      if (type === SurveyType.FREE_TEXT) {
        options.value = [];
      }
    };

    const isValid = () => {
      if (!surveyType.value) return false;
      if (
        surveyType.value === SurveyType.MULTIPLE_CHOICE &&
        options.value.length === 0
      ) {
        return false;
      }

      if (surveyType.value === SurveyType.MULTIPLE_CHOICE) {
        return !options.value.some(option => option.value === "");
      }

      if (question.value === "") return false;
      return true;
    };

    const submit = () => {
      if (!surveyType.value) return;
      if (
        surveyType.value === SurveyType.MULTIPLE_CHOICE &&
        options.value.length === 0
      )
        return;

      const surveyPayload: ChatSurvey = {
        type: surveyType.value,
        uuid: survey.value?.uuid,
        question: question.value,
        options: options.value.map(option => option.value)
      };
      props.onSubmitHandler(surveyPayload);
    };
    const cancel = () => {
      survey.value = null;
      setupOptions();
      props.onCancelHandler();
    };
    const addOption = () => {
      if (options.value.length >= props.maxOptions) {
        return;
      }
      options.value.push({
        key: (options.value.length + 1).toString(),
        label: `${t("message.surveyOptionTitle")} ${options.value.length + 1}`,
        value: ""
      });
    };

    const removeOption = () => {
      if (options.value.length <= props.minOptions) {
        return;
      }
      options.value.pop();
    };

    const updateOptions = (event: InputEvent) => {
      const target = event.target as HTMLInputElement;
      const key = target.attributes.getNamedItem("data-key")?.value;
      options.value.forEach((option, index) => {
        if (option.key === key) {
          options.value[index].value = target.value;
        }
      });
    };

    return {
      t,
      options,
      question,
      SurveyType,
      surveyType,
      showTypeSelect,
      showSurveyTypeSelect,
      choseSurveyType,
      isValid,
      submit,
      cancel,
      addOption,
      removeOption,
      updateOptions,
      textMaxLength: SURVEY_TEXT_MAX_LENGTH
    };
  }
});
