import { ActionTree } from "vuex";
import api, { LoginResponse } from "@/utils/api";
import { RootState } from ".";
import { MutationTypes } from "./mutations";
import { AxiosResponse } from "axios";
import localforage from "localforage";

import {
  AugmentedActionContext,
  ChatMessage,
  ChatRoom,
  ChatRoomMode,
  ChatSurvey,
  ChatTopic,
  ChatUser,
  FlashMessage,
  SurveyState
} from "./types";

export enum ActionTypes {
  // AUTH
  FIND_AND_SET_LOGIN_TOKEN = "FIND_AND_SET_LOGIN_TOKEN",
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  UPDATE_STORED_USER = "UPDATE_STORED_USER",
  // USER
  FETCH_USER = "FETCH_USER",
  SET_USER_NAME = "SET_USER_NAME",
  BLOCK_USER = "BLOCK_USER",
  UNBLOCK_USER = "UNBLOCK_USER",
  FETCH_USERS = "FETCH_USERS",
  // CHAT
  FETCH_CHAT_ACTIVE_ROOM = "FETCH_CHAT_ACTIVE_ROOM",
  UPDATE_CHAT_MODE = "UPDATE_CHAT_MODE",
  UPDATE_CHAT_ACTIVATION_STATE = "UPDATE_CHAT_ACTIVATION_STATE",
  // MESSAGES
  FETCH_MESSAGES = "FETCH_MESSAGES",
  POST_MESSAGE = "POST_MESSAGE",
  SEND_MESSAGE = "SEND_MESSAGE",
  DELETE_MESSAGE = "DELETE_MESSAGE",
  DELETE_MESSAGES = "DELETE_MESSAGES",
  BLOCK_MESSAGE = "BLOCK_MESSAGE",
  APPROVE_MESSAGE = "APPROVE_MESSAGE",
  REVERT_APPROVAL_MESSAGE = "REVERT_APPROVAL_MESSAGE",
  // TOPICS
  CREATE_TOPIC = "CREATE_TOPIC",
  FETCH_TOPICS = "FETCH_TOPICS",
  START_TOPIC = "START_TOPIC",
  STOP_TOPIC = "STOP_TOPIC",
  DELETE_TOPIC = "DELETE_TOPIC",
  // SURVEYS
  SEND_SURVEY = "SEND_SURVEY",
  ANSWER_SURVEY = "ANSWER_SURVEY",
  FETCH_SURVEYS = "FETCH_SURVEYS",
  UPDATE_SURVEY = "UPDATE_SURVEY",
  START_SURVEY = "START_SURVEY",
  STOP_SURVEY = "STOP_SURVEY",
  CANCEL_SURVEY = "CANCEL_SURVEY",
  DELETE_SURVEY = "DELETE_SURVEY",
  // SURVEYS
  FETCH_SURVEYS_REMOTE_VIEW = "FETCH_SURVEYS_REMOTE_VIEW"
}

export interface Actions {
  [ActionTypes.FIND_AND_SET_LOGIN_TOKEN]({
    commit
  }: AugmentedActionContext): Promise<void>;
  [ActionTypes.LOGIN]({ commit }: AugmentedActionContext): Promise<void>;
  [ActionTypes.LOGOUT]({ commit }: AugmentedActionContext): Promise<void>;
  [ActionTypes.UPDATE_STORED_USER](
    { commit }: AugmentedActionContext,
    newUser: Partial<LoginResponse>
  ): Promise<void>;
  [ActionTypes.FETCH_USER]({ commit }: AugmentedActionContext): Promise<void>;
  [ActionTypes.FETCH_USERS]({
    commit,
    state
  }: AugmentedActionContext): Promise<void>;
  [ActionTypes.BLOCK_USER](
    { state }: AugmentedActionContext,
    userUuid: string
  ): Promise<void>;
  [ActionTypes.UNBLOCK_USER](
    { state }: AugmentedActionContext,
    userUuid: string
  ): Promise<void>;
  [ActionTypes.SET_USER_NAME](
    { commit }: AugmentedActionContext,
    newName: string
  ): Promise<void>;
  [ActionTypes.FETCH_CHAT_ACTIVE_ROOM](
    { commit }: AugmentedActionContext,
    chatRoomUuid: string
  ): Promise<void>;
  [ActionTypes.UPDATE_CHAT_MODE](
    { commit, state }: AugmentedActionContext,
    mode?: ChatRoomMode
  ): Promise<ChatRoom | void>;
  [ActionTypes.UPDATE_CHAT_ACTIVATION_STATE](
    { commit, state }: AugmentedActionContext,
    isActive?: boolean
  ): Promise<ChatRoom | void>;
  [ActionTypes.FETCH_MESSAGES](
    { commit }: AugmentedActionContext,
    limit?: number,
    offset?: number
  ): Promise<void>;
  [ActionTypes.POST_MESSAGE](
    { commit }: AugmentedActionContext,
    message: ChatMessage
  ): Promise<void>;
  [ActionTypes.SEND_MESSAGE](
    { commit, state }: AugmentedActionContext,
    message?: ChatMessage
  ): Promise<ChatMessage | void>;
  [ActionTypes.DELETE_MESSAGE](
    { state }: AugmentedActionContext,
    message: ChatMessage
  ): Promise<void>;
  [ActionTypes.DELETE_MESSAGES]({
    state
  }: AugmentedActionContext): Promise<void>;
  [ActionTypes.BLOCK_MESSAGE](
    { state }: AugmentedActionContext,
    message: ChatMessage
  ): Promise<void>;
  [ActionTypes.APPROVE_MESSAGE](
    { state }: AugmentedActionContext,
    message: ChatMessage
  ): Promise<void>;
  [ActionTypes.REVERT_APPROVAL_MESSAGE](
    { state }: AugmentedActionContext,
    message: ChatMessage
  ): Promise<void>;
  [ActionTypes.CREATE_TOPIC](
    { commit, state }: AugmentedActionContext,
    message?: ChatMessage
  ): Promise<ChatMessage | void>;
  [ActionTypes.FETCH_TOPICS](
    { commit }: AugmentedActionContext,
    limit?: number,
    offset?: number
  ): Promise<void>;
  [ActionTypes.START_TOPIC](
    { commit }: AugmentedActionContext,
    topic?: ChatTopic
  ): Promise<void>;
  [ActionTypes.STOP_TOPIC](
    { commit }: AugmentedActionContext,
    topic?: ChatTopic
  ): Promise<void>;
  [ActionTypes.DELETE_TOPIC](
    { commit }: AugmentedActionContext,
    topic?: ChatTopic
  ): Promise<void>;
  [ActionTypes.SEND_SURVEY](
    { commit, state }: AugmentedActionContext,
    survey?: ChatSurvey
  ): Promise<ChatSurvey | void>;
  [ActionTypes.ANSWER_SURVEY](
    { commit, state }: AugmentedActionContext,
    payload: { surveyUuid: string; answer: string }
  ): Promise<void>;
  [ActionTypes.FETCH_SURVEYS]({
    commit,
    state
  }: AugmentedActionContext): Promise<ChatSurvey[] | void>;
  [ActionTypes.FETCH_SURVEYS_REMOTE_VIEW]({
    commit,
    state
  }: AugmentedActionContext): Promise<ChatSurvey[] | void>;
  [ActionTypes.UPDATE_SURVEY](
    { commit, state }: AugmentedActionContext,
    survey?: ChatSurvey
  ): Promise<ChatSurvey | void>;
  [ActionTypes.START_SURVEY](
    { commit, state }: AugmentedActionContext,
    survey?: ChatSurvey
  ): Promise<ChatSurvey | void>;
  [ActionTypes.STOP_SURVEY](
    { commit, state }: AugmentedActionContext,
    survey?: ChatSurvey
  ): Promise<ChatSurvey | void>;
  [ActionTypes.CANCEL_SURVEY](
    { commit, state }: AugmentedActionContext,
    survey?: ChatSurvey
  ): Promise<ChatSurvey | void>;
  [ActionTypes.DELETE_SURVEY](
    { commit, state }: AugmentedActionContext,
    survey?: ChatSurvey
  ): Promise<ChatSurvey | void>;
}

const actions: ActionTree<RootState, RootState> & Actions = {
  [ActionTypes.FIND_AND_SET_LOGIN_TOKEN]: async ({
    commit,
    dispatch,
    state
  }) => {
    api.interceptors.request.use(config => {
      config.headers["x-language"] = state.language;
      return config;
    });

    const params = new URLSearchParams(window.location.search.substring(1));

    const defaultLoginToken = params.get("mvcltd");
    const roomUuid = params.get("r");
    if (roomUuid) {
      commit(MutationTypes.SET_DESIRED_ROOM, roomUuid);
    }

    const loginToken = params.get("mvclt");
    const localToken = await localforage.getItem<LoginResponse | null>(
      "mvc-login"
    );

    if (!loginToken && !localToken && !defaultLoginToken) {
      commit(MutationTypes.SET_ASK_FOR_LOGIN_CODE, true);
      return;
    }

    if (!loginToken && localToken) {
      const { accessToken } = localToken;
      commit(MutationTypes.SET_ACCESS_TOKEN, accessToken);

      api.interceptors.request.use(config => {
        config.headers["x-accesstoken"] = accessToken;
        return config;
      });

      api.interceptors.response.use(
        _ => _,
        error => {
          const status = error.response.status;
          const message =
            (error.response.message || error.response.data.message) ?? null;
          const flashMessage: FlashMessage = {
            type: "error",
            title: "message.error",
            message: "message.errorOccurred",
            messagePlain: message,
            duration: 5000
          };
          commit(MutationTypes.ADD_FLASH_MESSAGE, flashMessage);

          if ([401, 403].includes(status)) {
            dispatch(ActionTypes.LOGOUT);
            window.location.reload();
          }
          return error;
        }
      );

      await dispatch(ActionTypes.FETCH_USER);
      if (!state.activeRoom && state.user?.lastChatRoomUuid) {
        const desiredRoom = roomUuid ? roomUuid : state.user?.lastChatRoomUuid;
        await dispatch(ActionTypes.FETCH_CHAT_ACTIVE_ROOM, desiredRoom);
      }
      commit(MutationTypes.SET_LOGGED_IN, true);
      return;
    }

    // set login token when mvclt is set
    if (loginToken) {
      commit(MutationTypes.SET_LOGIN_TOKEN, loginToken);
      return;
    }

    // set default login when there is no session
    // if (defaultLoginToken && !localToken) {
    // always ensure session via the login token - to be in the right room - quick fix
    if (defaultLoginToken) {
      commit(MutationTypes.SET_LOGIN_TOKEN, defaultLoginToken);
      Promise.reject("No login token found");
    }
  },
  [ActionTypes.LOGIN]: async ({ commit, dispatch, state }) => {
    if (!state.loginToken && !state.loginCode) {
      commit(MutationTypes.SET_ASK_FOR_LOGIN_CODE, true);
      return Promise.reject("No login credentials found");
    }

    commit(MutationTypes.SET_IS_LOADING, true);
    let loginResponse: LoginResponse | null = null;
    try {
      const payload = state.loginToken
        ? { mvclt: state.loginToken, r: state.desiredRoomUuid }
        : { code: state.loginCode, r: state.desiredRoomUuid };

      const response = await api.post<string, AxiosResponse<LoginResponse>>(
        "api/auth/login",
        payload
      );
      loginResponse = response.data;
    } catch (error) {
      await dispatch(ActionTypes.LOGOUT);
      commit(MutationTypes.SET_ASK_FOR_LOGIN_CODE, true);
      commit(MutationTypes.SET_IS_LOADING, false);
      throw error;
    }

    if (!loginResponse) {
      commit(MutationTypes.SET_IS_LOADING, false);
      return;
    }
    const { accessToken, user, activeRoom } = loginResponse;
    commit(MutationTypes.SET_ACCESS_TOKEN, accessToken);
    commit(MutationTypes.SET_USER, user);
    commit(MutationTypes.SET_CHAT_ACTIVE_ROOM, {
      uuid: activeRoom.uuid
    });
    commit(MutationTypes.SET_LOGGED_IN, true);

    api.interceptors.request.use(config => {
      config.headers["x-accesstoken"] = accessToken;
      return config;
    });

    await dispatch(ActionTypes.FETCH_CHAT_ACTIVE_ROOM, activeRoom.uuid);

    localforage.setItem("mvc-login", { accessToken });
    commit(MutationTypes.SET_IS_LOADING, false);
  },
  [ActionTypes.LOGOUT]: async ({ commit }) => {
    commit(MutationTypes.RESET_AUTH, undefined);
    localforage.removeItem("mvc-login");

    commit(MutationTypes.SET_ACTIVE_USERS, []);
    commit(MutationTypes.SET_SERVER_MESSAGES, []);
    commit(MutationTypes.SET_MESSAGES, []);
    commit(MutationTypes.SET_ACTIVE_TOPIC, undefined);
    commit(MutationTypes.SET_ACTIVE_SURVEY, null);
  },
  [ActionTypes.UPDATE_STORED_USER]: async ({ commit }, newUser) => {
    try {
      const localToken = await localforage.getItem<LoginResponse | null>(
        "mvc-login"
      );
      if (localToken) {
        const updated: LoginResponse = { ...localToken, ...newUser };
        localforage.setItem("mvc-login", updated);
      }
    } catch (error) {
      commit(MutationTypes.SET_PROFILE_ERROR, true);
      console.log("Error saving user", error);
    }
  },
  [ActionTypes.FETCH_USER]: async ({ commit, state }) => {
    commit(MutationTypes.SET_IS_LOADING, true);
    try {
      const response = await api.get<string, AxiosResponse<ChatUser>>(
        "api/user",
        { params: { chatRoomUuid: state.activeRoom?.uuid } }
      );
      commit(MutationTypes.SET_USER, response.data);
    } catch (error) {
      console.log("Error loading user", error);
    }
    commit(MutationTypes.SET_IS_LOADING, false);
  },
  [ActionTypes.FETCH_USERS]: async ({ commit, state }) => {
    commit(MutationTypes.SET_IS_LOADING, true);
    try {
      const chatRoomUuid = state.activeRoom?.uuid;
      const response = await api.get<string, AxiosResponse<ChatUser[]>>(
        `api/chat/${chatRoomUuid}/users`
      );
      commit(MutationTypes.SET_ACTIVE_USERS, response.data);
    } catch (error) {
      console.log("Error loading user", error);
    } finally {
      commit(MutationTypes.SET_IS_LOADING, false);
    }
  },
  [ActionTypes.SET_USER_NAME]: async ({ commit }, newName) => {
    commit(MutationTypes.SET_PROFILE_SAVING, true);
    commit(MutationTypes.SET_PROFILE_ERROR, false);
    try {
      const response = await api.post<string, AxiosResponse<ChatUser>>(
        "/api/user/change-username",
        { name: newName }
      );

      const statusString = "" + response.status;
      if (statusString.startsWith("2")) {
        commit(MutationTypes.SET_USER, response.data);
      }
    } catch (error) {
      commit(MutationTypes.SET_PROFILE_ERROR, true);
      console.log("Error loading user", error);
    }
    commit(MutationTypes.SET_PROFILE_SAVING, false);
  },
  [ActionTypes.BLOCK_USER]: async ({ state }, userUuid) => {
    try {
      const url = `/api/chat/${state.activeRoom?.uuid}/users/${userUuid}/ban`;
      await api.post<string, AxiosResponse>(url);
    } catch (e) {
      console.log("Error banning a user", e);
    }
  },
  [ActionTypes.UNBLOCK_USER]: async ({ state }, userUuid) => {
    try {
      const url = `/api/chat/${state.activeRoom?.uuid}/users/${userUuid}/ban`;
      await api.delete<string, AxiosResponse>(url);
    } catch (e) {
      console.log("Error banning a user", e);
    }
  },
  [ActionTypes.FETCH_CHAT_ACTIVE_ROOM]: async ({ commit }, chatRoomUuid) => {
    commit(MutationTypes.SET_IS_LOADING, true);
    try {
      const response = await api.get<string, AxiosResponse<ChatRoom>>(
        `/api/chat/${chatRoomUuid}`
      );
      const room = response.data;
      commit(MutationTypes.SET_CHAT_ACTIVE_ROOM, room);
    } catch (error) {
      console.log("Error loading chatRoom", error);
    }
    commit(MutationTypes.SET_IS_LOADING, false);
  },
  [ActionTypes.UPDATE_CHAT_MODE]: async ({ commit, state }, mode) => {
    try {
      const response = await api.put<string, AxiosResponse<ChatRoom>>(
        `/api/chat/${state.activeRoom?.uuid}`,
        { mode: mode }
      );
      commit(MutationTypes.SET_CHAT_ACTIVE_ROOM, response.data);
    } catch (error) {
      console.log("Error loading user", error);
    }
  },
  [ActionTypes.UPDATE_CHAT_ACTIVATION_STATE]: async (
    { commit, state },
    isActive
  ) => {
    try {
      const response = await api.put<string, AxiosResponse<ChatRoom>>(
        `/api/chat/${state.activeRoom?.uuid}`,
        { isActive: isActive }
      );
      commit(MutationTypes.SET_CHAT_ACTIVE_ROOM, response.data);
    } catch (error) {
      console.log("Error loading user", error);
    }
  },
  [ActionTypes.FETCH_MESSAGES]: async ({ commit, state }, limit?, offset?) => {
    commit(MutationTypes.SET_IS_LOADING, true);
    if (!state.activeRoom) {
      Promise.reject("No active chatRoom");
    }
    try {
      const uuid = state.activeRoom?.uuid;
      const response = await api.get<string, AxiosResponse<ChatMessage[]>>(
        `/api/chat/${uuid}/messages`,
        { params: { limit, offset } }
      );
      const messages = response.data;
      commit(MutationTypes.SET_MESSAGES, messages);
    } catch (error) {
      console.log("Error loading messages", error);
    }
    commit(MutationTypes.SET_IS_LOADING, false);
  },
  [ActionTypes.POST_MESSAGE]: async ({ commit, state }, message) => {
    commit(MutationTypes.SET_IS_LOADING, true);
    try {
      await api.post<string, AxiosResponse<void>>(
        `/api/chat/${state.activeRoom}/messages`,
        {
          message
        }
      );
    } catch (error) {
      console.log("Error loading user", error);
    }
    commit(MutationTypes.SET_IS_LOADING, false);
  },
  [ActionTypes.SEND_MESSAGE]: async ({ commit, state }, message) => {
    if (
      !state.currentMessage ||
      !state.socketConnected ||
      state.currentMessage === ""
    ) {
      return;
    }
    const ts = Date.now().toString();
    message = {
      timeStamp: ts,
      message: state.currentMessage
    };
    commit(MutationTypes.RESET_CURRENT_MESSAGE, undefined);
    return message;
  },
  [ActionTypes.DELETE_MESSAGE]: async ({ state }, message) => {
    try {
      const url = `/api/chat/${state.activeRoom?.uuid}/messages/${message.uuid}`;
      await api.delete<string, AxiosResponse>(url);
    } catch (e) {
      console.log("Error banning a message", e);
    }
  },
  [ActionTypes.DELETE_MESSAGES]: async ({ state }) => {
    try {
      const url = `/api/chat/${state.activeRoom?.uuid}/messages`;
      await api.delete<string, AxiosResponse>(url);
    } catch (e) {
      console.log("Error banning a message", e);
    }
  },
  [ActionTypes.BLOCK_MESSAGE]: async ({ state }, message) => {
    try {
      const url = `/api/chat/${state.activeRoom?.uuid}/messages/${message.uuid}/ban`;
      await api.post<string, AxiosResponse>(url);
    } catch (e) {
      console.log("Error banning a message", e);
    }
  },
  [ActionTypes.APPROVE_MESSAGE]: async ({ state }, message) => {
    try {
      const url = `/api/chat/${state.activeRoom?.uuid}/messages/${message.uuid}/approve`;
      await api.post<string, AxiosResponse>(url);
    } catch (e) {
      console.log("Error approving a message", e);
    }
  },
  [ActionTypes.REVERT_APPROVAL_MESSAGE]: async ({ state }, message) => {
    try {
      const url = `/api/chat/${state.activeRoom?.uuid}/messages/${message.uuid}/approve`;
      await api.delete<string, AxiosResponse>(url);
    } catch (e) {
      console.log("Error approving a message", e);
    }
  },
  [ActionTypes.CREATE_TOPIC]: async ({ commit, state }, message) => {
    if (!message) {
      return;
    }

    try {
      const url = `/api/chat/${state.activeRoom?.uuid}/topics/`;
      const response = await api.post<string, AxiosResponse<ChatTopic[]>>(url, {
        messageUuid: message.uuid
      });
      commit(MutationTypes.SET_TOPICS, response.data);
    } catch (e) {
      console.log("Error marking a message", e);
    }
  },
  [ActionTypes.FETCH_TOPICS]: async ({ commit, state }, limit?, offset?) => {
    commit(MutationTypes.SET_IS_LOADING, true);
    if (!state.activeRoom) {
      Promise.reject("No active chatRoom");
    }

    try {
      const uuid = state.activeRoom?.uuid;
      const response = await api.get<string, AxiosResponse<ChatTopic[]>>(
        `/api/chat/${uuid}/topics`,
        { params: { limit, offset } }
      );
      const chatTopics = response.data;
      commit(MutationTypes.SET_TOPICS, chatTopics);
    } catch (error) {
      console.log("Error loading messages", error);
    }
    commit(MutationTypes.SET_IS_LOADING, false);
  },
  [ActionTypes.START_TOPIC]: async ({ commit, state }, topic: ChatTopic) => {
    commit(MutationTypes.SET_IS_LOADING, true);
    try {
      const uuid = state.activeRoom?.uuid;
      await api.post<string, AxiosResponse<ChatTopic[]>>(
        `/api/chat/${uuid}/topics/${topic.uuid}/start`
      );
    } catch (error) {
      console.log("Error loading messages", error);
    }
    commit(MutationTypes.SET_IS_LOADING, false);
  },
  [ActionTypes.STOP_TOPIC]: async ({ commit, state }, topic: ChatTopic) => {
    commit(MutationTypes.SET_IS_LOADING, true);
    try {
      const uuid = state.activeRoom?.uuid;
      await api.post<string, AxiosResponse<ChatTopic[]>>(
        `/api/chat/${uuid}/topics/${topic.uuid}/stop`
      );
    } catch (error) {
      console.log("Error loading messages", error);
    }
    commit(MutationTypes.SET_IS_LOADING, false);
  },
  [ActionTypes.DELETE_TOPIC]: async ({ commit, state }, topic: ChatTopic) => {
    commit(MutationTypes.SET_IS_LOADING, true);
    try {
      const uuid = state.activeRoom?.uuid;
      await api.delete<string, AxiosResponse<ChatTopic[]>>(
        `/api/chat/${uuid}/topics/${topic.uuid}`
      );
    } catch (error) {
      console.log("Error loading messages", error);
    }
    commit(MutationTypes.SET_IS_LOADING, false);
  },
  [ActionTypes.SEND_SURVEY]: async ({ commit, state }, survey) => {
    commit(MutationTypes.SET_IS_LOADING, true);

    try {
      const url = `/api/chat/${state.activeRoom?.uuid}/surveys`;
      await api.post<string, AxiosResponse<void>>(url, survey);
    } catch (e) {
      console.log("Error creating a survey", e);
    }

    commit(MutationTypes.SET_IS_LOADING, false);
  },
  [ActionTypes.ANSWER_SURVEY]: async ({ commit, state }, payload) => {
    commit(MutationTypes.SET_IS_LOADING, true);

    try {
      const url = `/api/chat/${state.activeRoom?.uuid}/surveys/${payload.surveyUuid}/answers`;
      await api.post<string, AxiosResponse<void>>(url, {
        answer: payload.answer
      });
    } catch (e) {
      console.log("Error creating a survey", e);
    }

    commit(MutationTypes.SET_IS_LOADING, false);
  },
  [ActionTypes.FETCH_SURVEYS]: async ({ commit, state }) => {
    commit(MutationTypes.SET_IS_LOADING, true);

    try {
      const url = `/api/chat/${state.activeRoom?.uuid}/surveys`;
      const response = await api.get<string, AxiosResponse<ChatSurvey[]>>(url);
      commit(MutationTypes.SET_SURVEYS, response.data);
    } catch (e) {
      console.log("Error creating a survey", e);
    }

    commit(MutationTypes.SET_IS_LOADING, false);
  },
  [ActionTypes.FETCH_SURVEYS_REMOTE_VIEW]: async ({ commit, state }) => {
    try {
      const url = `/api/chat/${state.activeRoom?.uuid}/surveys?remote=true`;
      const response = await api.get<string, AxiosResponse<ChatSurvey[]>>(url);

      let surveys = response.data;
      surveys = surveys.sort((a, b) => {
        if (a.state === b.state) {
          return 0;
        }

        if (a.state === SurveyState.OPENED) {
          return -1;
        }
        if (a.state === SurveyState.WAITING) {
          return -1;
        }

        if (a.state === SurveyState.CANCELLED) {
          return 1;
        }
        if (a.state === SurveyState.CLOSED) {
          return 2;
        }
        if (a.state === SurveyState.DELETED) {
          return 3;
        }

        return 0;
      });

      commit(MutationTypes.SET_SURVEYS_REMOTE_VIEW, surveys);
    } catch (e) {
      console.log("Error fetching remote surveys", e);
    }
  },
  [ActionTypes.UPDATE_SURVEY]: async ({ commit, state }, survey) => {
    commit(MutationTypes.SET_IS_LOADING, true);

    try {
      const url = `/api/chat/${state.activeRoom?.uuid}/surveys/${survey?.uuid}`;
      await api.put<string, AxiosResponse<ChatSurvey>>(url, survey);
    } catch (e) {
      console.log("Error updating a survey", e);
    }

    commit(MutationTypes.SET_IS_LOADING, false);
  },
  [ActionTypes.START_SURVEY]: async ({ commit, state }, survey) => {
    commit(MutationTypes.SET_IS_LOADING, true);

    try {
      const url = `/api/chat/${state.activeRoom?.uuid}/surveys/${survey?.uuid}/start`;
      const response = await api.post<string, AxiosResponse<ChatSurvey[]>>(url);
      if (response.data) {
        commit(MutationTypes.SET_SURVEYS, response.data);
      }
    } catch (e) {
      console.log("Error creating a survey", e);
    }

    commit(MutationTypes.SET_IS_LOADING, false);
  },
  [ActionTypes.STOP_SURVEY]: async ({ commit, state }, survey) => {
    commit(MutationTypes.SET_IS_LOADING, true);

    try {
      const url = `/api/chat/${state.activeRoom?.uuid}/surveys/${survey?.uuid}/close`;
      const response = await api.post<string, AxiosResponse<ChatSurvey[]>>(url);
      commit(MutationTypes.SET_SURVEYS, response.data);
    } catch (e) {
      console.log("Error creating a survey", e);
    }

    commit(MutationTypes.SET_IS_LOADING, false);
  },
  [ActionTypes.CANCEL_SURVEY]: async ({ commit, state }, survey) => {
    commit(MutationTypes.SET_IS_LOADING, true);

    try {
      const url = `/api/chat/${state.activeRoom?.uuid}/surveys/${survey?.uuid}/cancel`;
      const response = await api.post<string, AxiosResponse<ChatSurvey[]>>(url);
      commit(MutationTypes.SET_SURVEYS, response.data);
    } catch (e) {
      console.log("Error creating a survey", e);
    }

    commit(MutationTypes.SET_IS_LOADING, false);
  },
  [ActionTypes.DELETE_SURVEY]: async ({ commit, state }, survey) => {
    commit(MutationTypes.SET_IS_LOADING, true);

    try {
      const url = `/api/chat/${state.activeRoom?.uuid}/surveys/${survey?.uuid}`;
      const response = await api.delete<string, AxiosResponse<ChatSurvey[]>>(
        url
      );
      commit(MutationTypes.SET_SURVEYS, response.data);
    } catch (e) {
      console.log("Error creating a survey", e);
    }

    commit(MutationTypes.SET_IS_LOADING, false);
  }
};

export default actions;
