<template>
  <div ref="elem" class="username-form p-inputgroup" :class="getClasses()">
    <InputText
      v-model="userNameState"
      :placeholder="userName"
      :maxlength="textMaxLength"
      class="username"
      @keyup.enter="submit()"
    />
    <div class="charcount">{{ userNameState.length }}/{{ textMaxLength }}</div>
    <Button
      icon="pi pi-check"
      class="p-button-text"
      :disabled="!canSubmit()"
      @click="submit()"
    />
    <Button
      icon="pi pi-times"
      class="p-button-text close-icon"
      @click="cancel()"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType, ref } from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";

const NAME_INPUT_WARNING_THRESHOLD_NONE = 8;
const NAME_MAX_LENGTH = 16;

export default defineComponent({
  name: "UserNameForm",
  components: {
    InputText,
    Button
  },
  props: {
    userName: {
      type: String,
      default: () => ""
    },
    profileSaving: {
      type: Boolean,
      default: () => false
    },
    profileError: {
      type: Boolean,
      default: () => false
    },
    submitUserName: {
      type: Function as PropType<(message: string) => void>,
      required: true
    },
    cancelEdit: {
      type: Function as PropType<() => void>,
      required: true
    }
  },
  setup: props => {
    const userNameState = ref<string>(props.userName);

    const elem = ref<HTMLDivElement>();

    const canSubmit = () => userNameState.value.length <= NAME_MAX_LENGTH;
    const submit = () => {
      if (!canSubmit()) {
        return;
      }
      props.submitUserName(userNameState.value);
    };

    const cancel = () => {
      userNameState.value = props.userName;
      props.cancelEdit();
    };

    onMounted(() => {
      const input = elem.value?.querySelector("input");
      input?.focus();
      input?.select();
    });

    const getClasses = () => {
      return {
        none: userNameState.value.length <= NAME_INPUT_WARNING_THRESHOLD_NONE,
        warning: userNameState.value.length > NAME_MAX_LENGTH - 10,
        error: userNameState.value.length >= NAME_MAX_LENGTH
      };
    };

    return {
      userNameState,
      elem,
      canSubmit,
      getClasses,
      submit,
      cancel,
      textMaxLength: NAME_MAX_LENGTH
    };
  },
  data() {
    return {
      name: this.$props.userName
    };
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../theme/variables.scss";
.username-form {
  background-color: var(--surface-0);
  input {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .username {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  button {
    background-color: var(--surface-b);
  }
  .charcount {
    position: absolute;
    right: 80px;
    top: 1px;
    opacity: 0.9;
    font-size: 12px;
    color: $border-color-light-gray;
    z-index: 100;
  }
}
.close-icon {
  ::v-deep(.pi-times) {
    color: $color-btn-blue-normal;

    &:hover {
      color: $color-btn-blue-hover;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}
</style>
