
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "@/store";
import { useI18n } from "vue-i18n";
import ContextMenu from "primevue/contextmenu";
import UserItem from "@/components/UserItem.vue";
import { ActionTypes } from "@/store/actions";
import { ChatUser } from "@/store/types";
import ScrollPanel from "primevue/scrollpanel";

export default defineComponent({
  name: "UsersPage",
  components: {
    UserItem: UserItem,
    ScrollPanel
  },
  setup() {
    const store = useStore();
    const activeUsers = computed(() => store.getters.getActiveUsers);
    const contextModMenu = ref<ContextMenu>();
    onMounted(async () => {
      await store.dispatch(ActionTypes.FETCH_USERS);
    });

    const { t } = useI18n();
    return {
      t: t,
      users: activeUsers,
      contextModMenu: contextModMenu,

      openContextMenu(event: Event) {
        contextModMenu.value?.toggle(event);
      },
      blockUser: (user: ChatUser) => {
        const uuid = user?.uuid;
        if (!uuid) {
          return;
        }

        store.dispatch(ActionTypes.BLOCK_USER, uuid);
      },
      unblockUser: (user: ChatUser) => {
        const uuid = user?.uuid;
        if (!uuid) {
          return;
        }

        store.dispatch(ActionTypes.UNBLOCK_USER, uuid);
      }
    };
  }
});
