<template #footer>
  <CDialog
    v-model:visible="visible"
    :modal="true"
    :header="header"
    :block-scroll="blockScroll"
    :position="position"
    class="p-confirm-dialog"
    :breakpoints="breakpoints"
  >
    <div class="p-d-flex p-flex-column message p-mt-2">
      <div class="p-d-flex p-align-center message">
        <i :class="iconClass" />
        <span class="p-confirm-dialog-message">{{ message }}</span>
      </div>

      <div class="p-fluid p-mt-3 confirm-text">
        <InputText
          id="_input"
          v-model="confirmText"
          type="text"
          :placeholder="getPlaceholderText()"
          :maxlength="getMaxInputLength()"
          @input="onInputChange"
        />
      </div>
    </div>

    <template #footer>
      <Button
        :label="rejectLabel"
        :icon="rejectIcon"
        :class="rejectClass"
        autofocus
        @click="reject()"
      />
      <Button
        :disabled="!isValid"
        :label="acceptLabel"
        :icon="acceptIcon"
        :class="acceptClass"
        @click="accept()"
      />
    </template>
  </CDialog>
</template>

<script>
import ConfirmDialog from "primevue/confirmdialog";
import { ref, onUpdated } from "vue";
import InputText from "primevue/inputtext";
import { useI18n } from "vue-i18n";
import Button from "primevue/button";

export default {
  name: "ConfirmAndCheckDialog",
  components: {
    InputText: InputText,
    Button: Button
  },
  extends: ConfirmDialog,
  setup() {
    const { t } = useI18n();
    const isValid = ref(false);
    const confirmText = ref("");

    const updateConfirmationText = value => {
      confirmText.value = value;

      const confirmTextSpec = t("message.confirmDeleteEnterText");
      isValid.value =
        confirmText.value.toLowerCase() === confirmTextSpec.toLowerCase();
    };

    // triggered when the dialog is updated (shown/hidden), it is kept alive and keeps the values inside
    onUpdated(() => {
      updateConfirmationText("");
    });

    const getPlaceholderText = () => {
      return t("message.confirmDeleteEnterTextPrompt");
    };

    return {
      t,
      confirmText,
      isValid,
      onInputChange: () => {
        updateConfirmationText(event.target.value);
      },
      updateConfirmationText: updateConfirmationText,
      getPlaceholderText: getPlaceholderText,
      getMaxInputLength: () => {
        return getPlaceholderText().length;
      }
    };
  }
};
</script>

<style scoped>
.message,
.confirm-text {
  width: 100%;
}
</style>
