import { createApp } from "vue";
import App from "./App.vue";
import { store } from "./store";
import PrimeVue from "primevue/config";
import axios from "axios";
import VueAxios from "vue-axios";
import SocketIo from "@/plugins/socket-io";
import { createRouter, createWebHistory } from "vue-router";
import socket from "@/utils/socket";
import i18n from "@/locale/i18n";
import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";

const routes = [
  {
    path: "/",
    component: App
  }
];

const router = createRouter({
  routes: routes,
  history: createWebHistory()
});

createApp(App)
  .use(SocketIo, socket)
  .use(store)
  .use(VueAxios, axios)
  .use(router)
  .use(i18n)
  .use(PrimeVue, { ripple: true })
  .use(ConfirmationService)
  .use(ToastService)
  .mount("#chat-app");
